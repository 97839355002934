import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ASensor } from "../../types/endpoints/sensor/sensor";
// import { ASensor } from "../../api/sensorAPI";

export interface sensorState {
  sensorArr: ASensor[];
}

const initialState: sensorState = {
  sensorArr: [],
};

export const sensorSlice = createSlice({
  name: "SENSOR_SLICE",
  initialState,
  reducers: {
    storeSensor: (state, payload: PayloadAction<ASensor[]>) => {
      state.sensorArr = payload.payload;
    },
    clearState: () => initialState,
    // clearState: (state) => {
    //   Object.assign(state, initialState);
    // },
  },
});

export default sensorSlice.reducer;
export const { storeSensor, clearState: resetSensorStore } =
  sensorSlice.actions;
