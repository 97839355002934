import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { ScheduleObj, ASchedule, AScheduleManage } from "../../api/scheduleAPI";

export const scheduleStoreAPI = createApi({
  reducerPath: "scheduleStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/schedulereport`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ScheduleList"],
  endpoints: (builder) => {
    return {
      getScheduleList: builder.query<ASchedule[], Partial<ScheduleObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ASchedule[]>) => {
          let data = response.data.slice();

          // adding sort so the data wont jiggle around upon mutation
          data.sort((a, b) => {
            return a.type.localeCompare(b.type);
          });
          return data;
        },
        providesTags: ["ScheduleList"],
      }),
      createScheduleReport: builder.mutation<
        BackendDataShape<ASchedule>,
        ScheduleObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ScheduleList"],
      }),
      createManageSchedule: builder.mutation<
        BackendDataShape<ASchedule>,
        AScheduleManage
      >({
        query: (obj) => {
          return {
            url: "/manage",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ScheduleList"],
      }),

      deleteSchedule: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["ScheduleList"],
      }),

      toggleScheduleReportStatus: builder.mutation<
        BackendDataShape<string>,
        { schedulereportId: number }
      >({
        query: ({ schedulereportId }) => {
          return {
            url: "/status",
            method: "POST",
            body: { schedulereportId },
          };
        },
        invalidatesTags: ["ScheduleList"],
      }),
    };
  },
});

export const {
  useGetScheduleListQuery,
  useCreateScheduleReportMutation,
  useCreateManageScheduleMutation,
  useDeleteScheduleMutation,
  useToggleScheduleReportStatusMutation,
  util: { resetApiState: resetScheduleStoreAPI },
} = scheduleStoreAPI;
