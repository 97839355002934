import React from "react";
import { useLocation } from "react-router-dom";
import { KiddoElement } from "../components/Sidebar/SidebarItem";

interface Props {
  kiddos?: KiddoElement[];
  parentURL?: string;
}

interface ReturnItem {
  /**
   * if the NavbarItem match, either the parent or the child
   */
  match: boolean;
  /**
   * if the NavbarItem a parent, this will show which kiddo is matched.
   * if there is no kiddos, this will be empty string
   */
  matchKiddo: string;
}

const defaultVal: ReturnItem = {
  match: false,
  matchKiddo: "",
};

const useMatchKiddo = ({ kiddos, parentURL }: Props): ReturnItem => {
  const { pathname } = useLocation();
  // const [state, setState] = React.useState<ReturnItem>({match: false, matchKiddo: ""});

  return React.useMemo<ReturnItem>(() => {
    let arr = pathname.split("/");
    let foundIndex = arr.findIndex((val) => {
      let parentFindURL = parentURL?.split("/").slice(-1)[0];
      return parentFindURL === `${val}`;
    });

    // console.log({ parentURL, pathname, arr, foundIndex });
    if (foundIndex === -1) return defaultVal;
    if (kiddos && kiddos.length) {
      let parent = arr[foundIndex];
      let nextInArr = arr[foundIndex + 1];
      if (nextInArr) {
        let found = kiddos.find(({ kiddoURL }) => {
          const match = `/${parent}/${nextInArr}`;
          // console.log({ kiddoURL, match });
          return kiddoURL === match;
        });
        // console.log({ nextInArr, found });
        return found
          ? {
              match: true,
              matchKiddo: found.kiddoURL!,
            }
          : defaultVal;
      } else {
        return defaultVal;
      }
    } else {
      return { match: true, matchKiddo: "" };
    }
  }, [kiddos, parentURL, pathname]);

  // return state
};

export default useMatchKiddo;
