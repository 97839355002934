import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { ADeviceDetailsWithSensorAlert } from "../../api/deviceAPI";
import { AEnviroboxBasic, ADetailEnviroBoxObj } from "../../api/enviroboxAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { EnviroboxReqObj } from "../../types/endpoints/envirobox/envirobox";
// import { ASensorAlertWithSensorLog } from "../../types/endpoints/sensor/sensor";

export const dashboardEnviroboxStoreAPI = createApi({
  reducerPath: "dashbordEnviroboxStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["EnviroBoxList", "DetailEnviroBox", "SensorlogEnviroboxList"],
  endpoints: (builder) => {
    return {
      getEnvirobox: builder.query<AEnviroboxBasic, object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/envirobox" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["EnviroBoxList"],
        transformResponse: (response: BackendDataShape<AEnviroboxBasic>) => {
          return response.data;
        },
      }),
      getDetailSensorLogEnvirobox: builder.query<
        ADetailEnviroBoxObj[],
        EnviroboxReqObj
      >({
        query: (obj) => {
          return {
            url: "/sensorlog/envirobox",
            method: "POST",
            body: obj,
          };
        },
        providesTags: ["DetailEnviroBox"],
        transformResponse: (
          response: BackendDataShape<ADetailEnviroBoxObj[]>,
        ) => {
          return response.data;
        },
      }),
      getSensorLogEnvirobox: builder.query<
        ADeviceDetailsWithSensorAlert[],
        EnviroboxReqObj
      >({
        query: (obj) => {
          return {
            body: obj,
            url: "/sensorlog/envirobox",
            method: "POST",
          };
        },
        providesTags: (_, __, obj) => {
          // @ts-ignore
          let id: string | undefined = obj.period ? obj.period : undefined;
          return [
            "SensorlogEnviroboxList",
            { type: "SensorlogEnviroboxList", id },
          ];
        },
        transformResponse: (
          res: BackendDataShape<ADeviceDetailsWithSensorAlert[]>,
        ) => res.data,
      }),
    };
  },
});

export const {
  useGetEnviroboxQuery,
  useGetDetailSensorLogEnviroboxQuery,
  useGetSensorLogEnviroboxQuery,
  util: { resetApiState: resetDashboardEnviroboxAPI },
} = dashboardEnviroboxStoreAPI;
