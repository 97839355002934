import axios, { AxiosRequestConfig } from "axios";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { BasicSelectOpt, Nullable } from "../../types";
import { ACompany, ACompanyShort } from "../companyAPI";
import { BackendDataShape, LocationLinks } from "../config";
import CookieMonster from "../CookieMonster";
import { AnEmployeeType } from "../employeeAPI";
import { AShift } from "../shiftAPI";

/**
 * @todo refactor these interfaces and types to `src/types`
 * and put it in their respective folders
 */
export interface LocationObj {
  id?: number;
  name: string;
  companyId: number;
  status: number;
}

export interface LocationWithShift {
  name: string;
  shiftIds: number[];
}

export interface ALocation extends LocationObj {
  id: number;
  code: string | null;
  created_by: number;
  created_at: string;
  updated_by: number | null;
  updated_at: string;
  company: ACompanyShort;
  coordinate: GoogleMapsLatitudeLongitude | null;
  description: string | null;
  shifts: AShift[];
  sublocation1: ASubLocationObj[];
}

/**
 * Location in Gateway
 */
export interface ALocationShort {
  id: number;
  code: Nullable<string>;
  created_by: Nullable<number>;
  created_at: string;
  updated_by: Nullable<number>;
  updated_at: string;
  name: string;
  companyId: number;
  status: number;
  description: string;
}

export interface SubLocationObj {
  name: string;
  sub2?: string[];
  locationId?: number;
}

export interface ASubLocation2Obj {
  id: number;
  name: string;
  companyId: number;
  sublocation1Id: number;
  imgmap?: null | string;
}
export interface ASubLocationObj {
  id: number;
  name: string;
  companyId: number;
  locationId: number;
  imgmap?: null | string;
  sublocation2: ASubLocation2Obj[];
}

export interface ASubLocation1Obj {
  id: number;
  name: string;
  companyId: number;
  locationId: number;
  location: Omit<ALocation, "company" | "shifts">;
}

export type ALocationWithSublocation2 = {
  id: number;
  name: string;
  companyId: number;
  sublocation1Id: number;
  sublocation1: {
    id: number;
    name: string;
    companyId: number;
    locationId: number;
    location: Omit<ALocation, "company" | "shifts">;
  };
};

export interface GoogleMapsLatitudeLongitude {
  lat: number;
  lng: number;
}

export interface LocationPinpointReqObj extends GoogleMapsLatitudeLongitude {
  id: number;
}

export type GoogleMapsQueryParameters = {
  components: string;
  address: string;
};

export type AGoogleMapsAddress = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type AGoogleMapsResult = {
  address_components: AGoogleMapsAddress[];
  formatted_address: string;
  geometry: {
    bounds: {
      northeast: GoogleMapsLatitudeLongitude;
      southwest: GoogleMapsLatitudeLongitude;
    };
    location: GoogleMapsLatitudeLongitude;
    location_type: string;
    viewport: {
      northeast: GoogleMapsLatitudeLongitude;
      southwest: GoogleMapsLatitudeLongitude;
    };
  };
  partial_match: boolean;
  place_id: string;
  types: string[];
};

export type GoogleMapsGeocodeLocationResponse = {
  results: AGoogleMapsResult[];
  status: string;
};

export interface ALocationWOCompany extends Omit<ALocation, "company"> {}

export interface LocationAreaObj {
  name: string;
  centerPoint: {
    x: number;
    y: number;
  };
  width: number;
  height: number;
  locationId: number;
  restricted: boolean;
}

export interface ALocationArea extends LocationAreaObj {
  companyId: number;
  id: number;
  location: Omit<ALocation, "company" | "shifts" | "sublocation1">;
  employeetype: AnEmployeeType[];
  locationId: number;
  locationIds?: number[];
}

export interface ALocationAreaWithColor extends ALocationArea {
  color: string;
}

export interface ALocationAreaOpt
  extends ALocationArea,
    BasicSelectOpt<number> {}

export interface ALocationMapDetail {
  id: number;
  companyId: number;
  locationId: number;
  mapsize: {
    width: number;
    height: number;
  };
  pointcrop: Nullable<{ x: number; y: number }>;
  mapsizereal: {
    width: number;
    height: number;
  };
  mapsizecropped: Nullable<{ width: number; height: number }>;
  location: {
    id: number;
    name: string;
    companyId: number;
    code: string;
    status: number;
    coordinate: Nullable<GoogleMapsLatitudeLongitude>;
    description: Nullable<string>;
    created_by: number;
    created_at: string;
    updated_by: Nullable<number>;
    updated_at: string;
  };
}

// eslint-disable-next-line
const sampleMapLocationDetailFromBE: ALocationMapDetail[] = [
  {
    id: 10,
    companyId: 39,
    locationId: 83,
    mapsize: {
      width: 718,
      height: 418,
    },
    pointcrop: null,
    mapsizereal: {
      width: 55.2,
      height: 60.1,
    },
    mapsizecropped: null,
    location: {
      id: 83,
      name: "Pabrik CPU",
      companyId: 39,
      code: "BECPU-1",
      status: 1,
      coordinate: null,
      description: null,
      created_by: -1,
      created_at: "2022-09-01T17:00:00.000Z",
      updated_by: null,
      updated_at: "2022-09-01T17:00:00.000Z",
    },
  },
];
export interface ALocationSelected
  extends BasicSelectOpt<number>,
    Pick<ALocationArea, "locationId"> {}

// export const sampleLocationFromBE: ALocation = {
//   id: 32,
//   name: "Location 1",
//   companyId: 1,
//   code: null,
//   status: 1,
//   description: null,
//   shifts: [
//     {
//       id: 1,
//       name: "Shift 4",
//       companyId: 1,
//       start: "15:00:00+07",
//       end: "16:00:00+07",
//     },
//   ],
//   company: {
//     id: 1,
//     name: "PT. Petean",
//     description: "",
//     created_at: "2022-02-07T17:00:00.000Z",
//     updated_at: "2022-02-07T17:00:00.000Z",
//   },
//   created_by: 7,
//   created_at: "2022-04-10T17:00:00.000Z",
//   updated_by: null,
//   updated_at: "2022-04-10T17:00:00.000Z",
//   sublocation1: [
//     {
//       id: 7,
//       name: "testing",
//       companyId: 1,
//       locationId: 32,
//       sublocation2: [
//         {
//           id: 9,
//           name: "dua baru",
//           companyId: 1,
//           sublocation1Id: 7,
//         },
//         {
//           id: 3,
//           name: "Bawah meja",
//           companyId: 1,
//           sublocation1Id: 7,
//         },
//       ],
//     },
//   ],
// };

export const GoogleMapsLocationResponseSample: GoogleMapsGeocodeLocationResponse =
  {
    results: [
      {
        address_components: [
          {
            long_name: "Jalan Ikan Mungsing",
            short_name: "Jl. Ikan Mungsing",
            types: ["route"],
          },
          {
            long_name: "Perak Barat",
            short_name: "Perak Bar.",
            types: ["administrative_area_level_4", "political"],
          },
          {
            long_name: "Kecamatan Krembangan",
            short_name: "Kec. Krembangan",
            types: ["administrative_area_level_3", "political"],
          },
          {
            long_name: "Kota Surabaya",
            short_name: "Kota SBY",
            types: ["administrative_area_level_2", "political"],
          },
          {
            long_name: "Jawa Timur",
            short_name: "Jawa Timur",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "Indonesia",
            short_name: "ID",
            types: ["country", "political"],
          },
          {
            long_name: "60177",
            short_name: "60177",
            types: ["postal_code"],
          },
        ],
        formatted_address:
          "Jl. Ikan Mungsing, Perak Bar., Kec. Krembangan, Kota SBY, Jawa Timur 60177, Indonesia",
        geometry: {
          bounds: {
            northeast: {
              lat: -7.2243725,
              lng: 112.7263855,
            },
            southwest: {
              lat: -7.2300707,
              lng: 112.7248957,
            },
          },
          location: {
            lat: -7.2269731,
            lng: 112.7262961,
          },
          location_type: "GEOMETRIC_CENTER",
          viewport: {
            northeast: {
              lat: -7.2243725,
              lng: 112.7269895802915,
            },
            southwest: {
              lat: -7.2300707,
              lng: 112.7242916197085,
            },
          },
        },
        partial_match: true,
        place_id: "ChIJNeuT3df41y0RfCgfiZz1k2o",
        types: ["route"],
      },
    ],
    status: "OK",
  };

export const sampleLocationFromBE: ALocation = {
  id: 49,
  name: "Pabrik Cikarang",
  companyId: 24,
  code: null,
  status: 1,
  coordinate: { lat: 0, lng: 0 },
  description: null,
  created_by: 1,
  created_at: "2022-05-29T17:00:00.000Z",
  updated_by: null,
  updated_at: "2022-05-29T17:00:00.000Z",
  sublocation1: [
    {
      id: 27,
      name: "Gedung A",
      companyId: 24,
      locationId: 49,
      imgmap: "uploads/0f5877e5454c8f852dca551fb37cf493.png",
      sublocation2: [
        {
          id: 41,
          name: "Ruang A111",
          companyId: 24,
          sublocation1Id: 27,
          imgmap: null,
        },
        {
          id: 42,
          name: "Ruang A112",
          companyId: 24,
          sublocation1Id: 27,
          imgmap: null,
        },
        {
          id: 43,
          name: "Ruang A101",
          companyId: 24,
          sublocation1Id: 27,
          imgmap: null,
        },
      ],
    },
    {
      id: 28,
      name: "Gedung B",
      companyId: 24,
      locationId: 49,
      imgmap: "uploads/ea3c6681ff05896fb4d02384fae6b967.jpeg",
      sublocation2: [
        {
          id: 44,
          name: "Ruang B103",
          companyId: 24,
          sublocation1Id: 28,
          imgmap: null,
        },
        {
          id: 45,
          name: "Ruang B102",
          companyId: 24,
          sublocation1Id: 28,
          imgmap: null,
        },
      ],
    },
  ],
  company: {
    id: 24,
    name: "PT Papero Utama",
    description: "-",
    created_at: "2022-05-29T17:00:00.000Z",
    updated_at: "2022-05-29T17:00:00.000Z",
  },
  shifts: [],
};

export function createLocation(obj: LocationObj) {
  let url = `${LocationLinks.create}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };

  return axios.post<BackendDataShape<ALocation>>(url, obj, config);
}

export function findLocation(obj?: Partial<LocationObj>) {
  let url = `${LocationLinks.find}`;
  if (obj) {
    url += convertToEncodedURL(obj);
  }
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };

  return axios.get<BackendDataShape<ALocation[]>>(url, config);
}

/**
 *
 * @param id location id
 * @param obj location obj
 */
export function updateLocation(id: number, obj: Partial<LocationObj>) {
  let url = `${LocationLinks.update}/${id}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.patch<BackendDataShape<string>>(url, obj, config);
}

/**
 *
 * @param id device id
 */
export function deleteLocation(id: number) {
  let url = `${LocationLinks.delete}/${id}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.delete<BackendDataShape<string>>(url, config);
}
/**
 * @param locationId Location ID
 */
export const showBlueprintMapLocation = (locationId: number) => {
  let url = `${LocationLinks.showMap(locationId)}`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  /**
   * @todo add the api's return value
   */
  return axios.get(url, config);
};
/**
 * @param locationId Location ID
 */
export const showMapLocation = (locationId: number) => {
  let url = `${LocationLinks.showMap(locationId)}`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  /**
   * @todo add the api's return value
   */
  return axios.get(url, config);
};
/**
 * @param locationId Location ID
 */
export const getMapLocationDetail = (locationId: number) => {
  let url = `${LocationLinks.showMapDetail(locationId)}`;
  let config: AxiosRequestConfig = {
    // responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  /**
   * @todo add the api's return value
   */
  return axios.get(url, config);
};
/**
 * @param locationId Location ID
 * @param file file FormData
 */
export const addBlueprintMapLocation = (locationId: number, file: FormData) => {
  let url = `${LocationLinks.addMap(locationId)}`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  /**
   * @todo add the api's return value
   */
  return axios.post<BackendDataShape<string>>(url, file, config);
};
/**
 * @param locationId Location ID
 */
export const deleteBlueprintMapLocation = (locationId: number) => {
  let url = `${LocationLinks.deleteMap(locationId)}`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  /**
   * @todo add the api's return value
   */
  return axios.delete(url, config);
};
/**
 * @param id sublocation1 id
 */
export const showMapSublocation1 = (id: number) => {
  let url = `${LocationLinks.sublocation1.showMap(id)}`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  /**
   * @todo add the api's return value
   */
  return axios.get(url, config);
};
/**
 * @param id sublocation2 id
 */
export const showMapSublocation2 = (id: number) => {
  let url = `${LocationLinks.sublocation2.showMap(id)}`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.get(url, config);
};
/**
 * @param id sublocation1 id
 * @param file file
 */
export const addMapSublocation1 = (id: number, file: FormData) => {
  let url = `${LocationLinks.sublocation1.addMap(id)}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.post<BackendDataShape<string>>(url, file, config);
};
/**
 * @param id sublocation2 id
 * @param file file
 */
export const addMapSublocation2 = (id: number, file: FormData) => {
  let url = `${LocationLinks.sublocation2.addMap(id)}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.post<BackendDataShape<string>>(url, file, config);
};

/**
 * @param id sublocation1 id
 */
export const deleteMapSublocation1 = (id: number) => {
  let url = `${LocationLinks.sublocation1.deleteMap(id)}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.delete(url, config);
};
/**
 * @param id sublocation2 id
 */
export const deleteMapSublocation2 = (id: number) => {
  let url = `${LocationLinks.sublocation2.deleteMap(id)}`;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };
  return axios.delete(url, config);
};
