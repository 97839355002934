import { FC } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import useBreadCrumbStyle from "./style";
import { BreadCrumbObj } from "../../../types/breadCrumb";

interface Props {
  breadCrumbs?: BreadCrumbObj[];
}

const BreadCrumbs: FC<Props> = (props) => {
  const { breadCrumbs } = props;

  const classes = useBreadCrumbStyle();

  return (
    <Breadcrumb style={{ marginTop: 6, fontSize: 10 }}>
      {breadCrumbs?.map((breadCrumbItem) => {
        return (
          <BreadcrumbItem
            active={breadCrumbItem?.isActive ?? false}
            key={breadCrumbItem?.label}
          >
            {breadCrumbItem?.path ? (
              <Link
                to={breadCrumbItem?.path}
                className={classes.breadCrumbItem}
              >
                {breadCrumbItem?.label}
              </Link>
            ) : (
              <span className={classes.breadCrumbLabel}>
                {breadCrumbItem?.label}
              </span>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
