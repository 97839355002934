import React from "react";
import { ACompany, findCompany } from "../api/companyAPI";
import { AUsertype, findUsertype } from "../api/credentialsAPI/usertype";
import { useAppDispatch } from ".";
import { BasicSelectOpt } from "../types";
import { useGetUsertypeQuery } from "../store/usertypeStore/usertypeStoreAPI";

export interface AUserOpt extends BasicSelectOpt<number>, AUsertype {}

interface ReturnVal {
  arr: AUserOpt[];
  isLoading: boolean;
}

const useUserTypeValue = () => {
  const dispatch = useAppDispatch();
  const [userTypeList, setUserTypeList] = React.useState<AUsertype[]>();
  const [usertypeOpt, setUserTypeOpt] = React.useState<AUserOpt[]>();
  const [Loading, setLogLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLogLoading(true);
    findUsertype()
      .then(({ data: { data } }) => {
        let opt = data;
        setUserTypeList(opt);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLogLoading(false);
      });
  }, [dispatch]);
  return {
    userTypeList,
    Loading,
  };
};

const useUserOpts = () => {
  const {
    data: userTypeList,
    isLoading: Loading,
    isFetching,
  } = useGetUsertypeQuery({});
  const isLoading = Loading || isFetching;
  if (!userTypeList || userTypeList.length < 1)
    return {
      arr: [],
      isLoading,
    };
  return {
    arr: userTypeList.map((aCmp) => {
      return {
        label: aCmp.name,
        value: aCmp.id,
        ...aCmp,
      };
    }),
    isLoading,
  };
};

export { useUserTypeValue, useUserOpts };
