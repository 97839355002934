import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoMdExit, IoMdNotificationsOutline } from "react-icons/io";
import styled from "styled-components";
import { motion } from "framer-motion";
import { formatDistance } from "date-fns";
import { useLogout } from "../hooks/auth";
import TimeDisplays from "./TimeDisplays";
import useNotificationStyles from "./PageContainer.style";
import { useGetListNotificationQuery } from "../store/notificationStore/notificationStoreAPI";
import { useTheme } from "react-jss";
import { BasicTheme } from "../assets/theme";
import BreadCrumbs from "./molecules/BreadCrumbs";
import { BreadCrumbObj } from "../types/breadCrumb";

interface Props {
  withControl?: boolean;
  title?: string | ReactNode;
  isFixedWidth?: boolean;
  titleRightChildren?: React.ReactNode;
  breadCrumbItems?: BreadCrumbObj[];
}

export const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const TitleRightChildrenWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* align-items: center; */
  margin-bottom: 8px;
`;

const StyledMainWrapper = styled.main`
  margin-top: 16px;
`;

const PageContainer: React.FunctionComponent<Props> = ({
  children,
  withControl,
  title,
  isFixedWidth,
  titleRightChildren,
  breadCrumbItems,
}) => {
  // const theme = useTheme<BasicTheme>();
  const classes = useNotificationStyles();
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const menuNotificationRef = useRef(null) as RefObject<HTMLDivElement>;
  const logout = useLogout();
  const { data: notificationData, isFetching: isFetchingNotification } =
    useGetListNotificationQuery({});

  const onClickNotification = () => {
    setIsShowNotification((current) => {
      return !current;
    });
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuNotificationRef.current &&
        !menuNotificationRef.current.contains(event.target as null)
      ) {
        setIsShowNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuNotificationRef]);

  return (
    <div
      className={classes.container}
      style={{ width: isFixedWidth ? "auto" : "100%" }}
    >
      <div className={classes.navContainer}>
        <TimeDisplays
          containerStyle={{
            flex: 1,
          }}
          breadCrumbItems={breadCrumbItems}
        />
        {withControl ? (
          <div className={classes.controlContainer} ref={menuNotificationRef}>
            {isShowNotification && (
              <motion.div>
                <div className={classes.notificationBox}>
                  {isFetchingNotification && (
                    <motion.div
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      className={classes.notificationItem}
                    />
                  )}
                  <div className={classes.notificationItemBox}>
                    {!isFetchingNotification &&
                      notificationData?.map((item) => {
                        return (
                          <div
                            className={classes.notificationItem}
                            key={item.id}
                          >
                            <div className={classes.notificationItemText}>
                              <strong>{item?.device?.name}</strong> is currently
                              on
                              <span className={classes.notificationItemWarning}>
                                {item?.alert?.threatlevel === 2
                                  ? "DANGER"
                                  : "WARNING"}
                              </span>
                              status. Please check the device.
                            </div>
                            <div className={classes.notificationTime}>
                              {`${formatDistance(
                                new Date(),
                                new Date(item?.receivedon),
                                {
                                  addSuffix: true,
                                },
                              )} ago`}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className={classes.viewAllButton}>View All</div>
                </div>
              </motion.div>
            )}
            <button
              title="notifications"
              className={classes.actionBtn}
              onClick={onClickNotification}
            >
              <IoMdNotificationsOutline
                size={18}
                fontSize={18}
                strokeWidth={14}
              />
            </button>
            <button
              title="Logout"
              className={classes.logoutBtn}
              onClick={() => {
                logout();
              }}
            >
              <IoMdExit size={18} fontSize={18} />
            </button>
          </div>
        ) : (
          []
        )}
      </div>
      <StyledMainWrapper>
        {titleRightChildren ? (
          <TitleRightChildrenWrapper>
            <h1 className={classes.titleHeading}>{title}</h1>
            {titleRightChildren}
          </TitleRightChildrenWrapper>
        ) : (
          <h1 className={classes.titleHeading}>{title}</h1>
        )}
        <div className={classes.childrenContainer}>{children}</div>
      </StyledMainWrapper>
    </div>
  );
};

export default PageContainer;
