import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { AnEmployee, EmployeeObj } from "../../api/employeeAPI";

const employeeURL = "/employee";

export const employeeStoreAPI = createApi({
  reducerPath: "employeeStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/employeetracker`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["EmployeeList", "EmployeeTagList", "EmployeeTypeList"],
  endpoints: (builder) => {
    return {
      getEmployee: builder.query<AnEmployee[], Partial<EmployeeObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          // let url = "/employee/find" + params;
          let url = `${employeeURL}/find${params}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AnEmployee[]>) =>
          response.data,
        providesTags: ["EmployeeList"],
      }),
      createEmployee: builder.mutation<
        BackendDataShape<AnEmployee>,
        EmployeeObj
      >({
        query: (obj) => {
          return {
            url: `${employeeURL}/create`,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["EmployeeList"],
      }),
      updateEmployee: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<EmployeeObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `${employeeURL}/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EmployeeList"],
      }),

      deleteEmployee: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `${employeeURL}/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EmployeeList"],
      }),
    };
  },
});

export const {
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  util: { resetApiState: resetEmployeeStoreAPI },
} = employeeStoreAPI;
