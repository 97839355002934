import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { AMachine, workOrderDataMachine } from "../../api/machineOEEAPI";

export const machineOeeStoreAPI = createApi({
  reducerPath: "machineOEEStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/oee/machine`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MachineOEE"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getMachine: builder.query<AMachine[], Partial<workOrderDataMachine>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AMachine[]>) => {
          return response.data;
        },
        providesTags: ["MachineOEE"],
      }),

      //   createDevice: builder.mutation<BackendDataShape<ADeviceBasic>, DeviceObj>(
      //     {
      //       query: (obj) => {
      //         return {
      //           url: "/create",
      //           body: obj,
      //           method: "POST",
      //         };
      //       },
      //       invalidatesTags: ["DeviceList"],
      //     },
      //   ),

      //   updateDevice: builder.mutation<
      //     BackendDataShape<string>,
      //     {
      //       /**
      //        * device Id
      //        */
      //       id: number;
      //       data: Partial<DeviceObj>;
      //     }
      //   >({
      //     query: ({ data, id }) => {
      //       return {
      //         url: `/update/${id}`,
      //         body: data,
      //         method: "PATCH",
      //       };
      //     },
      //     invalidatesTags: ["DeviceList"],
      //   }),

      //   deleteDevice: builder.mutation<
      //     BackendDataShape<string>,
      //     {
      //       /**
      //        * device Id
      //        */
      //       id: number;
      //     }
      //   >({
      //     query: ({ id }) => {
      //       return {
      //         url: `/delete/${id}`,
      //         method: "DELETE",
      //       };
      //     },
      //     invalidatesTags: ["DeviceList"],
      //   }),
      //   getDeviceRelation: builder.query<
      //     ADeviceSensorRelation[],
      //     Partial<AnAlertData>
      //   >({
      //     query: (obj) => {
      //       let params = "";
      //       if (obj) {
      //         params = convertToEncodedURL(obj);
      //         params = params.length ? "?" + params : "";
      //       }
      //       let url = "/findrelation" + params;
      //       return {
      //         url,
      //         method: "GET",
      //       };
      //     },
      //     transformResponse: (
      //       response: BackendDataShape<ADeviceSensorRelation[]>,
      //     ) => response.data,
      //     providesTags: ["DeviceRelationList"],
      //   }),
    };
  },
});

export const {
  useGetMachineQuery,
  util: { resetApiState: resetMachineOEEStoreAPI },
} = machineOeeStoreAPI;
