import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { ADeviceType } from "../../types/endpoints/devicetype/devicetype";

export const devicetypeStoreAPI = createApi({
  reducerPath: "devicetypeStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/devicetype`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Devicetypelist"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getDevicetypes: builder.query<ADeviceType[], { id?: number }>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ url, obj });
          return {
            url,
            method: "GET",
          };
        },
        providesTags: (res, err, { id }) => [
          { type: "Devicetypelist", id },
          "Devicetypelist",
        ],
        transformResponse: (res: BackendDataShape<ADeviceType[]>) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetDevicetypesQuery,
  util: { resetApiState: resetDevicetypeStoreAPI },
} = devicetypeStoreAPI;
