import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ALocation,
  LocationObj,
  LocationPinpointReqObj,
  LocationWithShift,
  SubLocationObj,
} from "../../api/LocationAPI";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const locationStoreAPI = createApi({
  reducerPath: "locationStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/location`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LocationList", "Sublocation1List", "Sublocation2List"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getLocation: builder.query<ALocation[], Partial<LocationObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ALocation[]>) =>
          response.data,
        providesTags: ["LocationList"],
      }),
      createLocation: builder.mutation<
        BackendDataShape<ALocation>,
        LocationObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["LocationList"],
      }),

      createLocationWithShift: builder.mutation<
        BackendDataShape<ALocation>,
        LocationWithShift
      >({
        query: (obj) => {
          return {
            url: "/createwithshift",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["LocationList"],
      }),

      updateLocationWithShift: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<LocationWithShift>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/updatewithshift/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LocationList"],
      }),

      updateLocation: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<LocationObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LocationList"],
      }),

      deleteLocation: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["LocationList"],
      }),

      createSubLocation: builder.mutation<
        BackendDataShape<ALocation>,
        SubLocationObj
      >({
        query: (obj) => {
          return {
            url: "/sub1/createwithsub2",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["LocationList"],
      }),

      updateSubLocation: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<SubLocationObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/sub1/updatewithsub2/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LocationList"],
      }),
      deleteSubLocation: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/sub1/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["LocationList"],
      }),
      addLocationImage: builder.mutation<BackendDataShape<string>, FormData>({
        query: (obj) => {
          return {
            url: `/addimage`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["LocationList"],
      }),
      addPinpointLocation: builder.mutation<
        BackendDataShape<string>,
        LocationPinpointReqObj
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/addpinpoint/${id}`,
            method: "PATCH",
            body: rest,
          };
        },
        invalidatesTags: ["LocationList"],
      }),
      addBlueprintMapLocation: builder.mutation<
        BackendDataShape<string>,
        { locationId: number; file: FormData }
      >({
        query: ({ locationId, file }) => {
          return {
            url: `/map/upload/${locationId}`,
            method: "POST",
            body: file,
          };
        },
        invalidatesTags: ["LocationList"],
      }),
    };
  },
});

export const {
  useGetLocationQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useUpdateLocationMutation,
  useCreateLocationWithShiftMutation,
  useUpdateLocationWithShiftMutation,
  useCreateSubLocationMutation,
  useDeleteSubLocationMutation,
  useUpdateSubLocationMutation,
  useAddLocationImageMutation,
  useAddPinpointLocationMutation,
  useAddBlueprintMapLocationMutation,
  util: { resetApiState: resetLocationStoreAPI },
} = locationStoreAPI;
