import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  scheduleArr: [],
};

export const scheduleSlice = createSlice({
  name: "SCHEDULE_SLICE",
  initialState,
  reducers: {
    storeSchedule: (state, payload: PayloadAction<any[]>) => {
      state.scheduleArr = payload.payload;
    },
    clearState: () => initialState,
  },
});

export default scheduleSlice.reducer;
export const { storeSchedule, clearState: resetScheduleStore } =
  scheduleSlice.actions;
