import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnUsageData } from "../../api/dashboardDetailAPI";
import {
  DeviceSensorAlert,
  DeviceSensorLog,
  OEEDetailingReport,
  ReportOEEGroupMachine,
  ReportOEEUnder75,
} from "../../api/reportAPI";

export interface ReportState {
  logArr: AnUsageData[];
  logDeviceSensorReport: DeviceSensorLog[];
  logDeviceSensorAlert: DeviceSensorAlert[];
  logOEEReport: ReportOEEUnder75[];
  logOEEDetailMachineReport: OEEDetailingReport[];
  logOEEGroupMachineReport: ReportOEEGroupMachine[];
  logEnergyMeterReport: DeviceSensorLog[];
}

const initialState: ReportState = {
  logArr: [],
  logDeviceSensorReport: [],
  logDeviceSensorAlert: [],
  logOEEReport: [],
  logOEEDetailMachineReport: [],
  logOEEGroupMachineReport: [],
  logEnergyMeterReport: [],
};

export const reportSlice = createSlice({
  name: "REPORT_SLICE",
  initialState,
  reducers: {
    storeReport: (state, payload: PayloadAction<AnUsageData[]>) => {
      state.logArr = payload.payload;
    },
    storeDeviceSensorReport: (
      state,
      payload: PayloadAction<DeviceSensorLog[]>,
    ) => {
      state.logDeviceSensorReport = payload.payload;
    },
    storeDeviceSensorAlert: (
      state,
      payload: PayloadAction<DeviceSensorAlert[]>,
    ) => {
      state.logDeviceSensorAlert = payload.payload;
    },
    storeOEEReport: (state, payload: PayloadAction<ReportOEEUnder75[]>) => {
      state.logOEEReport = payload.payload;
    },
    storeOEEMachineReport: (
      state,
      payload: PayloadAction<OEEDetailingReport[]>,
    ) => {
      state.logOEEDetailMachineReport = payload.payload;
    },
    storeOEEGroupMachineReport: (
      state,
      payload: PayloadAction<ReportOEEGroupMachine[]>,
    ) => {
      state.logOEEGroupMachineReport = payload.payload;
    },
    storeEnergyMeterReport: (
      state,
      payload: PayloadAction<DeviceSensorLog[]>,
    ) => {
      state.logEnergyMeterReport = payload.payload;
    },
    clearState: () => initialState,
  },
});

export default reportSlice.reducer;
export const {
  storeReport,
  storeDeviceSensorReport,
  storeDeviceSensorAlert,
  storeOEEReport,
  storeOEEMachineReport,
  storeOEEGroupMachineReport,
  storeEnergyMeterReport,
  clearState: resetReportStore,
} = reportSlice.actions;
