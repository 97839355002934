import React from "react";
import { AGateway, findGateway } from "../api/gatewayAPI";
import { useAppDispatch } from "../hooks";

const useGatewayValue = () => {
  const dispatch = useAppDispatch();
  const [gatewayList, setGatewayList] = React.useState<AGateway[]>();

  React.useEffect(() => {
    findGateway().then(({ data: { data } }) => {
      setGatewayList(data);
      // console.log(data);
    });
  }, [dispatch]);
  // console.log(gatewayList);
  return {
    gatewayList,
  };
};

export default useGatewayValue;
