import React from "react";
import SVG, { Props as SVGProps } from "react-inlinesvg";
import { createUseStyles } from "react-jss";
import { RequireSome } from "../../types";
import { current_svg } from "./svg";

export type MinSVGProps = RequireSome<SVGProps, "title" | "src">;

export const BasicIconProps: MinSVGProps = {
  title: "basic_icon",
  src: current_svg,
  fill: "black",
  stroke: "black",
  height: "13",
  width: "13",
  viewBox: "0 0 48 48",
  fontSize: "1rem",
};

const useStyles = createUseStyles<"icon", Partial<SVGProps>>({
  icon: {
    "> path": ({ fill }) => {
      return {
        fill,
        stroke: fill,
      };
    },
  },
});
export const BaseIcon: React.FunctionComponent<Partial<SVGProps>> = ({
  className,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <SVG
      {...BasicIconProps}
      preProcessor={(code) =>
        code.replace(/fill=".*?"/g, 'fill="currentColor"')
      }
      className={[classes.icon, className].join(" ")}
      {...props}
    />
  );
};

export const CurrentSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  ...rest
}) => (
  <BaseIcon
    title={title ?? "current_icon"}
    src={src ?? current_svg}
    {...rest}
  />
);
