import React from "react";

type Props = {
  screenName?: string;
};

const LoadingScreen = ({ screenName }: Props) => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "red",
      }}
    >
      <p>
        Loading {screenName && screenName.length ? screenName : "Your Page"}...
      </p>
    </div>
  );
};

export default LoadingScreen;
