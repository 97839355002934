import { Container } from "@mui/material";
import React from "react";
import styled from "styled-components";
import {
  alert_landing_page,
  desktopLandingMockup,
  desktopLandingMockup2,
  employeeTrackerWebp,
  energymeterWebp,
  enviroboxWebp,
  hourglass_black_white_landing_png,
  landing_bg1_png,
  loralogo_png,
  map_landing_page,
  m_buslogo_png,
  nbiotlogo_png,
  oeeliveWebp,
  oeeWebp,
  rapidsense_by_datacakra,
  rapidsense_white,
  report_landing_page,
  sigfoxlogo_png,
  waterlevelWebp,
} from "../assets/images";
import { COLORS, mq } from "../assets/theme";
import { StyledGrid } from "./DashboardPages/WeatherStationDashboard/style";
import {
  OverlayBackgroundNonImage,
  OverlayImage,
  OverlayText,
  OverlayTextDescription,
  OverlayTextTitle,
  StyledCardImage,
  StyledImageContainer,
} from "./HomePage";
import welcomingPageDescription from "../config/welcomingPageDescription.json";
import {
  instagram_icon_svg,
  twitter_icon_svg,
  whatsapp_icon_svg,
} from "../assets/icons/svg";

type Props = {};

const StyledNavHeader = styled.header`
  background-color: white;
  width: 100%;
`;

const StyledNav = styled.nav`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

const StyledLandingDiv = styled.div`
  /* background: #f2f3f7;
  min-height: 100vh; */
`;

const StyledNavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledListMenuWrapper = styled.ul`
  list-style-type: none;
  display: inline-flex;
  gap: 24px;
  margin-bottom: 0;
  li {
    font-size: 14px;
  }
`;

const StyledImageLanding = styled.img`
  transition: all 0.2s;
  max-width: 360px;
  ${mq[3]} {
    max-width: 768px;
  }
  :hover {
    transform: scale(1.06);
  }
`;

const StyledH1HeadingLanding = styled.h1`
  font-size: 36px;
  font-weight: 700;
  ${mq[2]} {
    font-size: calc(36px + 16 * ((100vw - 568px) / (768 - 568)));
  }
  ${mq[3]} {
    font-size: 48px;
  }
  ${mq[4]} {
    font-size: 64px;
  }
`;

const StyledButton = styled.button<{ variant: "outlined" | "filled" }>`
  background: ${(props) =>
    props.variant === "filled" ? COLORS.blue_1 : "transparent"};
  color: ${(props) =>
    props.variant === "outlined" ? COLORS.blue_1 : COLORS.white};
  border-radius: 6px;
  transition: all 0.2s;
  :hover {
    background: ${COLORS.blue_1};
    color: ${COLORS.white};
    transform: translateY(-2px);
  }
  border: ${(props) =>
    props.variant === "outlined" ? `1px solid ${COLORS.blue_1}` : "none"};
  padding: 12px 32px;
  font-size: 14px;
`;

const StyledDivFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mq[3]} {
    flex-direction: row;
  }
`;

const IotLogoGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 320px);
  ${mq[3]} {
    grid-template-columns: repeat(2, 320px);
    flex-direction: row;
  }
  grid-gap: 8px;
  grid-row-gap: 24px;
`;

const StyledMenuAndButtonNav = styled.div`
  display: none;
  ${mq[3]} {
    display: contents;
  }
`;

const StyledFooter = styled.footer`
  color: white;
`;

const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${mq[3]} {
    flex-direction: row;
  }
`;

const StyledUlFooter = styled.ul`
  list-style-type: none;
  font-size: 12px;
  li {
    margin-bottom: 6px;
  }
`;

const LandingPage = (props: Props) => {
  return (
    <StyledLandingDiv>
      <StyledNavHeader>
        <Container maxWidth="xl" fixed>
          <StyledNav>
            <StyledNavWrapper>
              <StyledImageLanding
                src={rapidsense_white}
                alt=""
                style={{ width: 160 }}
              />
              <StyledMenuAndButtonNav>
                <StyledListMenuWrapper>
                  <li>Product</li>
                  <li>Price</li>
                  <li>About</li>
                </StyledListMenuWrapper>
              </StyledMenuAndButtonNav>
            </StyledNavWrapper>
            <StyledNavWrapper>
              <StyledMenuAndButtonNav>
                <StyledButton variant="outlined">Price Simulator</StyledButton>
                <StyledButton variant="filled">Contact Sales</StyledButton>
              </StyledMenuAndButtonNav>
            </StyledNavWrapper>
          </StyledNav>
        </Container>
      </StyledNavHeader>
      <main>
        <section style={{ background: "#f2f3f7", padding: "32px 0" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <div style={{ padding: "96px 16px" }}>
                <h1 style={{ fontWeight: 700, fontSize: 72 }}>
                  Lorem Ipsum Dolor Sit Amet
                </h1>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 22,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Rapidsense is an advanced IoT platform designed specifically
                  to handle heavy-duty enterprise-grade IoT solutions. It
                  supports wide-range of industrial sensors, various business
                  purposes, and cloud scalability.
                </h4>
                <StyledButton variant="filled" style={{ padding: "12px 32px" }}>
                  Contact Sales
                </StyledButton>
              </div>
              <div>
                <StyledImageLanding
                  src={desktopLandingMockup}
                  alt="desktopmockup1"
                />
              </div>
            </StyledDivFlex>
          </Container>
        </section>
        <section style={{ padding: "32px 0" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <div style={{ padding: "96px 64px", textAlign: "center" }}>
                <h1 style={{ fontWeight: 700, fontSize: 72 }}>
                  Modul Previews
                </h1>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 22,
                    lineHeight: 1.65,
                    padding: "24px",
                  }}
                >
                  Prepare & choose your needs from now on. Rapidsense product
                  helps you to handle the engineering needs of IoT while you’re
                  focus on your business.
                </h4>
                <StyledGrid gridNum={2} gridDirection="column" gridGap={16}>
                  {/* Energy Meter */}
                  <StyledImageContainer>
                    <StyledCardImage src={energymeterWebp} />
                    <OverlayBackgroundNonImage>
                      <OverlayTextTitle>
                        <span style={{ fontWeight: 600 }}>Energy Meter</span>
                      </OverlayTextTitle>
                    </OverlayBackgroundNonImage>
                    <OverlayImage>
                      <OverlayText>
                        <span style={{ fontWeight: 600 }}>Energy Meter</span>
                        <OverlayTextDescription>
                          {welcomingPageDescription.energymeter}
                        </OverlayTextDescription>
                      </OverlayText>
                    </OverlayImage>
                  </StyledImageContainer>
                  {/* Energy Meter */}
                  {/* OEE */}
                  <StyledImageContainer>
                    <StyledCardImage src={oeeWebp} />
                    <OverlayBackgroundNonImage>
                      <OverlayTextTitle>
                        <span>
                          OEE{" "}
                          <span style={{ fontWeight: 400 }}>
                            (Overall Equipment Effectiveness)
                          </span>
                        </span>
                      </OverlayTextTitle>
                    </OverlayBackgroundNonImage>
                    <OverlayImage>
                      <OverlayText>
                        <span>
                          OEE{" "}
                          <span style={{ fontWeight: 400 }}>
                            (Overall Equipment Effectiveness)
                          </span>
                        </span>
                        <OverlayTextDescription>
                          {welcomingPageDescription.oee}
                        </OverlayTextDescription>
                      </OverlayText>
                    </OverlayImage>
                  </StyledImageContainer>
                  <StyledImageContainer>
                    <StyledCardImage src={oeeliveWebp} />
                    <OverlayBackgroundNonImage>
                      <OverlayTextTitle>
                        <span style={{ fontWeight: 600 }}>
                          OEE Live Operator
                        </span>
                      </OverlayTextTitle>
                    </OverlayBackgroundNonImage>
                    <OverlayImage>
                      <OverlayText>
                        <span style={{ fontWeight: 600 }}>
                          OEE Live Operator
                        </span>
                        <OverlayTextDescription>
                          {welcomingPageDescription.oeelive}
                        </OverlayTextDescription>
                      </OverlayText>
                    </OverlayImage>
                  </StyledImageContainer>
                  <StyledImageContainer>
                    <StyledCardImage src={enviroboxWebp} />
                    <OverlayBackgroundNonImage>
                      <OverlayTextTitle>
                        <span style={{ fontWeight: 600 }}>Envirobox</span>
                      </OverlayTextTitle>
                    </OverlayBackgroundNonImage>
                    <OverlayImage>
                      <OverlayText>
                        <span style={{ fontWeight: 600 }}>Envirobox</span>
                        <OverlayTextDescription>
                          {welcomingPageDescription.envirobox}
                        </OverlayTextDescription>
                      </OverlayText>
                    </OverlayImage>
                  </StyledImageContainer>
                  <StyledImageContainer>
                    <StyledCardImage src={waterlevelWebp} />
                    <OverlayBackgroundNonImage>
                      <OverlayTextTitle>
                        <span style={{ fontWeight: 600 }}>Water Level</span>
                      </OverlayTextTitle>
                    </OverlayBackgroundNonImage>
                    <OverlayImage>
                      <OverlayText>
                        <span style={{ fontWeight: 600 }}>Water Level</span>
                        <OverlayTextDescription>
                          {welcomingPageDescription.waterlevel}
                        </OverlayTextDescription>
                      </OverlayText>
                    </OverlayImage>
                  </StyledImageContainer>
                  <StyledImageContainer>
                    <StyledCardImage src={employeeTrackerWebp} />
                    <OverlayBackgroundNonImage>
                      <OverlayTextTitle>
                        <span style={{ fontWeight: 600 }}>
                          Employee Tracker
                        </span>
                      </OverlayTextTitle>
                    </OverlayBackgroundNonImage>
                    <OverlayImage>
                      <OverlayText>
                        <span style={{ fontWeight: 600 }}>
                          Employee Tracker
                        </span>
                        <OverlayTextDescription>
                          {welcomingPageDescription.employeetracker}
                        </OverlayTextDescription>
                      </OverlayText>
                    </OverlayImage>
                  </StyledImageContainer>
                </StyledGrid>
              </div>
            </StyledDivFlex>
          </Container>
        </section>
        <section style={{ background: "#f2f3f7", padding: "72px 0" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <div>
                <StyledImageLanding
                  src={desktopLandingMockup2}
                  alt="desktopmockup2"
                />
              </div>
              <div style={{ padding: "96px 32px", marginLeft: 64 }}>
                <StyledH1HeadingLanding>
                  Alarm and Notifications
                </StyledH1HeadingLanding>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Rapidsense will give a signal if there’s something happens.
                  Minimizing the nescience on small mistakes.
                </h4>
              </div>
            </StyledDivFlex>
          </Container>
        </section>
        <section
          style={{
            background: `linear-gradient(90deg, rgba(0, 18, 40, 0.8) 2.5%, rgba(0, 18, 40, 0.7) 29.48%, rgba(0, 0, 0, 0) 74.77%), url(${landing_bg1_png})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 640,
            padding: "32px 0",
          }}
        >
          <Container maxWidth="xl" fixed>
            <div style={{ marginLeft: 64 }}>
              <div style={{ color: COLORS.white, marginTop: 96 }}>
                <h2 style={{ fontWeight: 700, fontSize: 52 }}>
                  Industrial Grade
                </h2>
                <h5
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Based on industry 4.0 that can improve your business more than
                  before. Bring new experience and profit.
                </h5>
              </div>
            </div>
          </Container>
        </section>
        <section style={{ padding: "72px 64px" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <IotLogoGridWrapper>
                <StyledImageLanding src={loralogo_png} alt="loralogo" />
                <StyledImageLanding src={m_buslogo_png} alt="m_buslogo_png" />
                <StyledImageLanding src={sigfoxlogo_png} alt="sigfoxlogo_png" />
                <StyledImageLanding src={nbiotlogo_png} alt="nbiotlogo_png" />
              </IotLogoGridWrapper>
              <div style={{ padding: "96px 32px", marginLeft: 64 }}>
                <h1 style={{ fontWeight: 700, fontSize: 64 }}>
                  LoRa NB-IoT Capable
                </h1>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Capable to serve different commercial and technical
                  requirements even with low-power devices.
                </h4>
              </div>
            </StyledDivFlex>
          </Container>
        </section>
        <section
          style={{
            background: `url(${hourglass_black_white_landing_png})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 640,
            padding: "32px 0",
          }}
        >
          <Container maxWidth="xl" fixed>
            <div style={{ marginLeft: 64 }}>
              <div style={{ marginTop: 128 }}>
                <h2 style={{ fontWeight: 700, fontSize: 48 }}>
                  Real Time Tracking and Monitoring
                </h2>
                <h5
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Reduce unseen problem, because Rapidsense using real time
                  tracking while monitoring.
                </h5>
              </div>
            </div>
          </Container>
        </section>
        <section style={{ padding: "72px 64px" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <div>
                <StyledImageLanding
                  src={alert_landing_page}
                  alt="alert_landing_page"
                />
              </div>
              <div style={{ padding: "96px 32px", marginLeft: 64 }}>
                <StyledH1HeadingLanding>
                  Alert Management
                </StyledH1HeadingLanding>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Capable to serve different commercial and technical
                  requirements even with low-power devices.
                </h4>
              </div>
            </StyledDivFlex>
          </Container>
        </section>
        <section style={{ background: "#f2f3f7", padding: "72px 0" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <div style={{ padding: "96px 32px", marginLeft: 64 }}>
                <StyledH1HeadingLanding>
                  Report Management
                </StyledH1HeadingLanding>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Reduce unseen problem, because Rapidsense using real time
                  tracking while monitoring.
                </h4>
              </div>
              <div>
                <StyledImageLanding
                  src={report_landing_page}
                  alt="report_landing_page"
                />
              </div>
            </StyledDivFlex>
          </Container>
        </section>
        <section style={{ padding: "72px 64px" }}>
          <Container maxWidth="xl" fixed>
            <StyledDivFlex>
              <div>
                <StyledImageLanding
                  src={map_landing_page}
                  alt="map_landing_page"
                />
              </div>
              <div style={{ padding: "96px 0", marginLeft: 64 }}>
                <StyledH1HeadingLanding>
                  Location & Map Management
                </StyledH1HeadingLanding>
                <h4
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.65,
                    padding: "24px 0",
                  }}
                >
                  Capable to serve different commercial and technical
                  requirements even with low-power devices.
                </h4>
              </div>
            </StyledDivFlex>
          </Container>
        </section>
      </main>
      <StyledFooter>
        <div style={{ background: "#001228", padding: "48px 56px" }}>
          <Container maxWidth="xl" fixed>
            <StyledFooterContainer>
              <div style={{ flex: "0 0 50%" }}>
                <img src={rapidsense_by_datacakra} alt="rapidsense-logo" />
                <p style={{ marginTop: 16, maxWidth: 220 }}>
                  18 Office Park Lt.25 Suite A2. Jl. TB Simatupang No.18,
                  Kebagusan Pasar Minggu, Jakarta Selatan, 12520
                </p>
                <p
                  style={{
                    marginTop: 48,
                    maxWidth: 220,
                    fontWeight: 600,
                    marginBottom: 0,
                  }}
                >
                  Email
                </p>
                <p style={{ marginTop: 2 }}>reza@datacakra.com</p>
              </div>
              <div>
                <div style={{ display: "flex", marginTop: 32, gap: 32 }}>
                  <StyledUlFooter>
                    <li>FAQ</li>
                    <li>Data Attribution</li>
                    <li>Report Problem</li>
                  </StyledUlFooter>
                  <StyledUlFooter>
                    <li>Cookie Settings</li>
                    <li>Privacy Policy</li>
                    <li>Terms and Conditions</li>
                  </StyledUlFooter>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    marginTop: 32,
                    gap: 32,
                    marginLeft: 20,
                    fontSize: 12,
                    alignItems: "center",
                  }}
                >
                  <p style={{ margin: 0 }}>Get in Touch - </p>
                  <div
                    style={{
                      display: "inline-flex",
                      gap: 16,
                      transform: "translateX(-26px)",
                    }}
                  >
                    <img
                      src={whatsapp_icon_svg}
                      alt="whatsapp"
                      style={{ maxWidth: 16 }}
                    />
                    <img
                      src={twitter_icon_svg}
                      alt="twitter"
                      style={{ maxWidth: 16 }}
                    />
                    <img
                      src={instagram_icon_svg}
                      alt="instagram"
                      style={{ maxWidth: 16 }}
                    />
                  </div>
                </div>
                {/* <img src={rapidsense_by_datacakra} alt="rapidsense-logo" />
                <p style={{ marginTop: 16, maxWidth: 220 }}>
                  18 Office Park Lt.25 Suite A2. Jl. TB Simatupang No.18,
                  Kebagusan Pasar Minggu, Jakarta Selatan, 12520
                </p>
                <p
                  style={{
                    marginTop: 48,
                    maxWidth: 220,
                    fontWeight: 600,
                    marginBottom: 0,
                  }}
                >
                  Email
                </p>
                <p style={{ marginTop: 2 }}>reza@datacakra.com</p> */}
              </div>
            </StyledFooterContainer>
          </Container>
        </div>
        <div
          style={{
            padding: 16,
            background: "#000D1C",
            textAlign: "center",
            color: COLORS.grey,
          }}
        >
          © 2022 Rapidsense | Rapidsense by PT Datacakra Solusi Jaganata
        </div>
      </StyledFooter>
    </StyledLandingDiv>
  );
};

export default LandingPage;
