import { FC } from "react";
import toast, { Toast } from "react-hot-toast";
import { useTheme } from "react-jss";
import { BasicTheme } from "../../assets/theme";
import { motion } from "framer-motion";
import { BaseIcon } from "../../assets/icons";
import { xiconWhite_svg } from "../../assets/icons/svg";
import { easing } from "../../config/transitionProps";

type toastStatus = "success" | "error";

type Props = {
  t: Toast;
  status: toastStatus;
  headerText: string;
  description: string;
};

const CustomToast: FC<Props> = (props) => {
  const {
    t,
    status,
    headerText = "Example Header!",
    description = "Example Description!",
  } = props;
  const theme = useTheme<BasicTheme>();
  return (
    <motion.div
      style={{
        backgroundColor:
          status === "success"
            ? theme.chart.green
            : status === "error"
            ? theme.dangerColor
            : theme.gray,
        color: "white",
        borderRadius: "4px",
        padding: "1rem 1.5rem",
        width: "200px",
        position: "relative",
        display: "flex",
        gap: 2,
        flexDirection: "column",
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: t.visible ? 1 : 0,
        transition: { duration: 0.6, ease: easing },
      }}
    >
      <BaseIcon
        src={xiconWhite_svg}
        title="closeIcon"
        style={{
          fill: "white",
          stroke: "transparent",
          strokeWidth: 0,
          position: "absolute",
          right: 8,
          cursor: "pointer",
        }}
        onClick={() => toast.dismiss(t.id)}
      />
      <span style={{ fontWeight: 600, fontSize: "12px" }}>{headerText}</span>
      <span style={{ fontSize: "10px" }}>{description}</span>
    </motion.div>
  );
};

export default CustomToast;
