import { format } from "date-fns";
import React from "react";
import { createUseStyles } from "react-jss";
import { BasicTheme } from "../assets/theme";
import { BreadCrumbObj } from "../types/breadCrumb";
import BreadCrumbs from "./molecules/BreadCrumbs";

interface Props {
  containerStyle?: React.CSSProperties;
  breadCrumbItems?: BreadCrumbObj[];
}

const useTimeDisplayStyles = createUseStyles((theme: BasicTheme) => ({
  dateTimeTextWrapper: {
    color: theme.black_1,
    "@media (max-width: 1367px)": {
      fontSize: "12px",
    },
    "@media (min-width: 1920px)": {
      fontSize: "15px",
    },
    margin: 0,
    padding: 0,
  },
}));

const TimeDisplays: React.FunctionComponent<Props> = ({
  containerStyle,
  breadCrumbItems,
}) => {
  const [curDate, setCurDate] = React.useState<Date>(new Date());
  const classes = useTimeDisplayStyles();

  React.useEffect(() => {
    let interval = setInterval(() => {
      setCurDate(new Date());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={containerStyle}>
      <div className={classes.dateTimeTextWrapper}>
        <span>{format(curDate, "EEE, dd MMMM yyyy")} - </span>
        <span style={{ fontWeight: 600 }}>{format(curDate, "HH:mm:ss")}</span>
        <BreadCrumbs breadCrumbs={breadCrumbItems} />
      </div>
    </div>
  );
};

export default TimeDisplays;
