import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ACompany,
  ACompanyWithModuleColor,
  CompanyObj,
} from "../../api/companyAPI";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { AModule } from "../../api/moduleAPI";
import { COLORS } from "../../assets/theme";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const companyStoreAPI = createApi({
  reducerPath: "companyStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/company`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["CompanyList"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getCompanies: builder.query<
        ACompany[],
        {
          id?: number;
          name?: string;
        }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ACompany[]>) =>
          response.data,
        providesTags: ["CompanyList"],
      }),

      createCompany: builder.mutation<
        BackendDataShape<Pick<ACompany, "id">[]>,
        CompanyObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        transformResponse: (response: BackendDataShape<ACompany[]>) => response,
        invalidatesTags: ["CompanyList"],
      }),

      updateCompany: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * gateway Id
           */
          id: number;
          data: Partial<CompanyObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["CompanyList"],
      }),
      subscribeModuleCompany: builder.mutation<
        unknown,
        {
          /**
           * gateway Id
           */
          companyIds: number[];
          moduleIds: number[];
        }
      >({
        query: (obj) => {
          return {
            url: `/subscribe`,
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["CompanyList"],
      }),
      deleteCompany: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * company Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["CompanyList"],
      }),
    };
  },
});

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useSubscribeModuleCompanyMutation,
  util: { resetApiState: resetCompanyStoreAPI },
} = companyStoreAPI;
