import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { AModule } from "../../api/moduleAPI";
import { COLORS } from "../../assets/theme";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const moduleStoreAPI = createApi({
  reducerPath: "moduleStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/modules`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ModuleList"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getModules: builder.query<AModule[], Partial<AModule>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AModule[]>) =>
          response.data.map((val, idx) => {
            const moduleColors = [
              COLORS.blue_1,
              COLORS.green_4,
              COLORS.purple_1,
              COLORS.dark_grey_1,
              COLORS.cyan,
              COLORS.asphalt,
            ];

            return {
              ...val,
              groupColor: moduleColors[idx % moduleColors.length],
            };
          }),
        providesTags: ["ModuleList"],
      }),

      createModule: builder.mutation<unknown, Pick<AModule, "name">>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ModuleList"],
      }),

      updateModule: builder.mutation<unknown, AModule>({
        query: (obj) => {
          return {
            url: `/update/${obj.id}`,
            body: obj,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ModuleList"],
      }),

      deleteModule: builder.mutation<unknown, Pick<AModule, "id">>({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["ModuleList"],
      }),
    };
  },
});

export const {
  useGetModulesQuery,
  useCreateModuleMutation,
  useUpdateModuleMutation,
  useDeleteModuleMutation,
  util: { resetApiState: resetModuleStore },
} = moduleStoreAPI;
