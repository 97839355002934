import styled from "styled-components";
import { COLORS } from "../../../assets/theme";

const WeatherStationFlexWrapper = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  color: ${COLORS.black_1};
  @media (min-width: 768px) {
    flex-direction: row;
  }
  gap: 1rem;
`;

const WeatherStationLeftSection = styled.div`
  flex: 1;
  @media (min-width: 768px) {
    flex: 0 0 53%;
  }
`;

const WeatherStationRightSection = styled.div`
  flex: 1;
  justify-content: space-between;
`;

const WeatherTodayWrapper = styled.div`
  display: flex;
  padding: 2rem;
  background: white;
  border-radius: 0.75rem;
`;

const StyledCloudImage = styled.img<{ size?: number }>`
  height: ${(props) => (props.size ? `${props.size}px` : "auto")};
  width: ${(props) => (props.size ? `${props.size}px` : "auto")};
  margin-right: 1rem;
`;

const StyledAtomImage = styled.img<{ height?: number; width?: number }>`
  height: ${(props) => (props.height ? `${props.height}px` : "45px")};
  width: ${(props) => (props.width ? `${props.width}px` : "45px")};
  margin-right: 1rem;
`;

const CloudFlexWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const WeatherTodayFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WeatherTextH3 = styled.h3`
  font-size: 12px;
`;

const WeatherUnitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const WeatherDegreeText = styled.span<{ fontSize?: number }>`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "24px")};
  font-weight: 500;
`;

const WeatherTodayConditionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

const WeatherTodayConditionItem = styled.div<{ isBorderedRight: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  padding-right: 2rem;
  border-right: ${({ isBorderedRight }) =>
    isBorderedRight ? `1px solid ${COLORS.light_grey_1}` : "none"};
`;

const BodyText = styled.p<{
  textColor?: string;
  paddingBottom?: number;
  fontWeight?: number;
  fontSize?: number;
}>`
  margin: 0;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "10px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : "0px"};
  color: ${(props) => (props.textColor ? props.textColor : COLORS.black_1)};
`;

const FlexParameterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ParameterHeaderItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ParameterItemWrapper = styled.div<{ isBorderedBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: ${({ isBorderedBottom }) =>
    isBorderedBottom ? `1px solid ${COLORS.light_grey_1}` : "none"};
`;

const ParameterHeaderTitle = styled.div`
  font-size: 10px;
  color: ${COLORS.black_1};
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: ${COLORS.light_grey_2};
`;

const ParameterAtomWrapper = styled.div`
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StyledGrid = styled.div<{
  gridDirection: "column" | "row";
  gridGap: number;
  gridNum: number;
  marginY?: number;
  marginX?: number;
}>`
  display: grid;
  margin: ${(props) => (props.marginY ? `${props.marginY}px 0px` : "0px")};
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 16px;
  @media (min-width: 768px) {
    ${(props) =>
      props.gridDirection === "column"
        ? {
            gridTemplateColumns: `repeat(${props.gridNum}, 1fr)`,
            gap: props.gridGap,
          }
        : props.gridDirection === "row" && {
            gridTemplateRows: `repeat(${props.gridNum}, 1fr)`,
            gap: props.gridGap,
          }};
  }
`;

const WeatherStationCardWrapper = styled.div`
  display: flex;
  padding: 1.6rem;
  flex-direction: column;
  gap: 8px;
  background: white;
  border-radius: 0.75rem;
  justify-content: space-between;
`;

export {
  BodyText,
  WeatherDegreeText,
  WeatherStationFlexWrapper,
  WeatherStationLeftSection,
  WeatherStationRightSection,
  WeatherTextH3,
  WeatherTodayConditionItem,
  WeatherTodayConditionWrapper,
  WeatherTodayFlex,
  WeatherTodayWrapper,
  WeatherUnitWrapper,
  CloudFlexWrapper,
  StyledCloudImage,
  StyledAtomImage,
  FlexParameterWrapper,
  ParameterItemWrapper,
  StyledGrid,
  ParameterHeaderTitle,
  WeatherStationCardWrapper,
  ParameterHeaderItemWrapper,
  ParameterAtomWrapper,
};
