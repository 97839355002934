import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertObjWithData } from "../../types/endpoints/alert/alert";

export interface alertState {
  alertArr: AlertObjWithData[];
}

const initialState: alertState = {
  alertArr: [],
};

export const alertSlice = createSlice({
  name: "SENSOR_SLICE",
  initialState,
  reducers: {
    storeAlert: (state, payload: PayloadAction<AlertObjWithData[]>) => {
      state.alertArr = payload.payload;
    },
    clearStore: (state) => {
      state = initialState;
    },
  },
});

export default alertSlice.reducer;
export const { storeAlert, clearStore: resetAlertStore } = alertSlice.actions;
