import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDataWOToken } from "../../api/credentialsAPI";

export interface userState {
  userData: UserDataWOToken | undefined;
}

const initialState: userState = {
  userData: undefined,
};

export const userSlice = createSlice({
  name: "USER_SLICE",
  initialState,
  reducers: {
    storeUserData: (state, payload: PayloadAction<UserDataWOToken>) => {
      state.userData = payload.payload;
    },
    removeUserData: (state) => {
      state.userData = undefined;
    },
  },
});

export default userSlice.reducer;
export const { storeUserData, removeUserData } = userSlice.actions;
