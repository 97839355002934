import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ALocation } from "../../api/LocationAPI";

export interface locationState {
  locationArr: ALocation[];
}

const initialState: locationState = {
  locationArr: [],
};

export const locationSlice = createSlice({
  name: "COMPANY_SLICE",
  initialState,
  reducers: {
    storeLocation: (state, payload: PayloadAction<ALocation[]>) => {
      state.locationArr = payload.payload;
    },
    clearState: () => initialState,
    // clearState: (state) => {
    //   Object.assign(state, initialState);
    // },
  },
});

export default locationSlice.reducer;
export const { storeLocation, clearState: clearLocationStore } =
  locationSlice.actions;
