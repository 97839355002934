import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACompany } from "../../api/companyAPI";

export interface companyState {
  companyArr: ACompany[];
}

const initialState: companyState = {
  companyArr: [],
};

export const companySlice = createSlice({
  name: "COMPANY_SLICE",
  initialState,
  reducers: {
    storeCompany: (state, payload: PayloadAction<ACompany[]>) => {
      state.companyArr = payload.payload;
    },
    clearStore: (state) => {
      state = initialState;
    },
  },
});

export default companySlice.reducer;
export const { storeCompany, clearStore: resetCompanyStore } =
  companySlice.actions;
