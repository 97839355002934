import { createUseStyles } from "react-jss";
import { BasicTheme, COLORS } from "../../../assets/theme";

const useBreadCrumbStyle = createUseStyles((theme: BasicTheme) => {
  return {
    breadCrumbItem: {
      textDecoration: "none",
      color: COLORS.blue_3,
    },
    breadCrumbLabel: {
      color: COLORS.dark_grey_2,
    },
  };
});

export default useBreadCrumbStyle;
