import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnUsageData } from "../../api/dashboardDetailAPI";

export interface DeviceDetailsState {
  dataArr: AnUsageData[];
}

const initialState: DeviceDetailsState = {
  dataArr: [],
};

export const dashboardDetailsSlice = createSlice({
  name: "DEVICE_DETAILS_SLICE",
  initialState,
  reducers: {
    storeDashboardDetails: (state, payload: PayloadAction<AnUsageData[]>) => {
      state.dataArr = payload.payload;
    },
    clearState: () => initialState,
    // clearState: (state) => {
    //   console.log("DASHBOARD DETAIL STORE CLEARED");
    //   Object.assign(state, initialState);
    // },
  },
});

export default dashboardDetailsSlice.reducer;
export const { storeDashboardDetails, clearState: resetDashboardDetailStore } =
  dashboardDetailsSlice.actions;

// export const dashboardDetailStoreAPI = createApi({
//   reducerPath: "dashboardDetailStoreAPI",
//   baseQuery: fetchBaseQuery({
//     baseUrl: `${UsedAPI}/dashboard`,
//     prepareHeaders: (headers) => {
//       let token = CookieMonster.loadCookie("jwt");
//       headers.set("Authorization", `Bearer ${token}`);
//       return headers;
//     },
//   }),
//   tagTypes: ["DeviceDetailsData"],
//   endpoints: (builder) => {
//     return {
//       getDeviceDetails: builder.query<BackendDataShape<UsageData>, UsageObj>({
//         query: ({
//           limit,
//           aftertime,
//           beforetime,
//           deviceId,
//           sensorcode,
//         }: UsageObj) => {
//           console.log({ limit, aftertime, beforetime, deviceId, sensorcode });
//           return {
//             url: "/sensorlog/find",
//             method: "POST",
//             body: {
//               limit,
//               beforetime,
//               aftertime,
//               deviceId,
//               sensorcode,
//             },
//           };
//         },
//         providesTags: (result, error, id) => [
//           { type: "DeviceDetailsData", id },
//         ],
//       }),
//     };
//   },
// });

// export const {
//   useGetDeviceDetailsQuery,
//   // useGetDailyQuery,
//   // useGetWeeklyQuery,
//   // useGetMonthlyQuery,
//   // useGetDailyMutation,
//   // useGetMonthlyMutation,
//   // useGetWeeklyMutation,
// } = dashboardDetailStoreAPI;
