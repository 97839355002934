import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { UsageData, UsagePeriod } from "../../api/dashboardAPI";
// import { LoginObj } from "../../api/credentialsAPI";

/**
 * WIP
 */
export const dashboardStoreAPI = createApi({
  reducerPath: "dashboardStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["UsageData"],
  endpoints: (builder) => {
    return {
      getUsage: builder.query<BackendDataShape<UsageData>, UsagePeriod>({
        query: (period) => {
          return {
            url: "/usage",
            method: "POST",
            body: {
              period,
              sensorcode: "edel",
            },
          };
        },
        providesTags: (_, __, id) => [{ type: "UsageData", id }, "UsageData"],
      }),
    };
  },
});

export const {
  useGetUsageQuery,
  // useGetDailyQuery,
  // useGetWeeklyQuery,
  // useGetMonthlyQuery,
  // useGetDailyMutation,
  // useGetMonthlyMutation,
  // useGetWeeklyMutation,
  util: { resetApiState: resetDashboardStoreAPI },
} = dashboardStoreAPI;
