import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { AnEmployeeTag, EmployeeTagObj } from "../../api/employeeAPI";
import { employeeStoreAPI } from "../employeeStore/employeeStoreAPI";

const employeeTagURL = "/employee/tag";

export const employeeTagStoreAPI = employeeStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getEmployeeTag: builder.query<AnEmployeeTag[], Partial<EmployeeTagObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = employeeTagURL + "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AnEmployeeTag[]>) =>
          response.data,
        providesTags: ["EmployeeTagList"],
      }),
      createEmployeeTag: builder.mutation<
        BackendDataShape<AnEmployeeTag>,
        Partial<EmployeeTagObj>
      >({
        query: (obj) => {
          return {
            url: employeeTagURL + "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["EmployeeTagList"],
      }),
      updateEmployeeTag: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<EmployeeTagObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `${employeeTagURL}/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EmployeeTagList"],
      }),

      deleteEmployeeTag: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `${employeeTagURL}/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EmployeeTagList"],
      }),

      resetEmployeeTag: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<EmployeeTagObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `${employeeTagURL}/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EmployeeTagList"],
      }),
    };
  },
});

export const {
  useCreateEmployeeTagMutation,
  useDeleteEmployeeTagMutation,
  useGetEmployeeTagQuery,
  useUpdateEmployeeTagMutation,
  useResetEmployeeTagMutation,
  util: { resetApiState: resetEmployeeTagStoreAPI },
} = employeeTagStoreAPI;
