import React from "react";
import { Toaster } from "react-hot-toast";
import { HashRouter, Route, Routes, Outlet } from "react-router-dom";
import LoaderPage from "./components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "./components/auth/AuthHOC";
import UserGate from "./components/auth/UserGate";
import {
  DashboardRoutes,
  EnergyMeterRoutes,
  ListOfRoutes,
  ManagementRoutes,
  ReportRoutes,
} from "./config/nav";
import Layout from "./layout/Layout";
import LandingPage from "./pages/LandingPage";

import NoMatch from "./pages/NoMatch";
import Redirector from "./pages/Redirector";

const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const VerifyPage = React.lazy(() => import("./pages/VerifyPage"));

const HomePage = React.lazy(() => import("./pages/HomePage"));

// DASHBOARD PAGES
const EnergyMeterPage = React.lazy(
  () => import("./pages/DashboardPages/EnergyMeterDashboard/EnergyMeterPage"),
);
const EnergyMeterDeviceListPage = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/EnergyMeterDashboard/EnergyMeterDeviceListPage"
    ),
);

const WaterLevelDashboardPage = React.lazy(
  () =>
    import("./pages/DashboardPages/WaterLevelDashboard/WaterLevelDashboard"),
);
// const OverallWaterLevelDashboardPage = React.lazy(
//   () => import("./pages/DashboardPages/OverallWaterLevelDashboard"),
// );

// const WaterLevelByLocationDashboardPage = React.lazy(
//   () => import("./pages/DashboardPages/WaterLevelByLocationDashboard"),
// );

const EmployeeProfilePage = React.lazy(
  () => import("./pages/EmployeeProfilePage"),
);

const WaterLevelLocationById = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/WaterLevelByLocationDashboard/DetailWaterLevelLocation/DetailWaterLevelLocation"
    ),
);

const EnviroboxPage = React.lazy(
  () => import("./pages/DashboardPages/EnviroboxDashboard/EnviroboxPage"),
);
const WeatherStationDashboardPage = React.lazy(
  () => import("./pages/DashboardPages/WeatherStationDashboard"),
);
const EmployeeTrackerDashboardPage = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/EmployeeTrackerDashboard/EmployeeTrackerDashboardPage"
    ),
);
const EmployeeActivitiesPage = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/EmployeeActivitiesDashboard/EmployeeActivitiesPage"
    ),
);
const DetailEnviroboxPage = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/EnviroboxDashboard/DetailEnviroboxPage/DetailEnviroboxPage"
    ),
);

const OEEPage = React.lazy(
  () => import("./pages/DashboardPages/OEEDashboard/OEEPage"),
);
const LocationMonitoringPage = React.lazy(
  () =>
    import(
      "./pages/DashboardPages/LocationMonitoringDashboard/LocationMonitoringPage"
    ),
);
// const MachinePage = React.lazy(() => import("./pages/MachinePage"));

const OEEMachineDetail = React.lazy(
  () => import("./pages/DashboardPages/OEEDashboard/OEEMachineDetail"),
);

// MANAGEMENT PAGES
const LocationPage = React.lazy(
  () => import("./pages/ManagementPages/LocationPage"),
);

const GatewaysPage = React.lazy(
  () => import("./pages/ManagementPages/GatewaysPage"),
);

const SensorTypePage = React.lazy(
  () => import("./pages/ManagementPages/SensorTypePage"),
);

const DevicesPage = React.lazy(
  () => import("./pages/ManagementPages/DevicesPage"),
);

const AreaManagementPage = React.lazy(
  () => import("./pages/ManagementPages/AreaManagementPage"),
);

const CompanyPage = React.lazy(
  () => import("./pages/ManagementPages/CompanyPage"),
);

const AccountPage = React.lazy(
  () => import("./pages/ManagementPages/AccountPage"),
);

const AlertPage = React.lazy(() => import("./pages/ManagementPages/AlertPage"));

const WorkOrderPage = React.lazy(
  () => import("./pages/ManagementPages/WorkOrderPage"),
);

const MachineManagementPage = React.lazy(
  () => import("./pages/ManagementPages/MachineManagementPage"),
);

// REPORT PAGES
const ReportPage = React.lazy(() => import("./pages/ReportPages/ReportTab"));

const Step1 = React.lazy(() => import("./pages/SchedulePages/Step1"));

const Step2 = React.lazy(() => import("./pages/SchedulePages/Step2"));

const Step3 = React.lazy(() => import("./pages/SchedulePages/Step3"));

const Step4 = React.lazy(() => import("./pages/SchedulePages/Step4"));

// PROFILE PAGES
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));

// MACHINE PAGES
const MachinePage = React.lazy(
  () => import("./pages/MachineDashboardPages/MachinePage"),
);

// PRODUCT PAGES
const ProductPage = React.lazy(
  () => import("./pages/ManagementPages/ProductPage"),
);

const ShiftPage = React.lazy(() => import("./pages/ManagementPages/ShiftPage"));

const EmployeePage = React.lazy(
  () => import("./pages/ManagementPages/EmployeePage"),
);

const TagPage = React.lazy(() => import("./pages/ManagementPages/TagPage"));

const EmployeeTypePage = React.lazy(
  () => import("./pages/ManagementPages/EmployeeTypePage"),
);

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Layout />
              </AuthHOC>
            }
          >
            <Route index element={<HomePage />} />
            {/* PROFILE ROUTE */}
            <Route path={ListOfRoutes.profile} element={<ProfilePage />} />
            {/* DASHBOARD ROUTES */}
            <Route
              path={DashboardRoutes.energyMeter}
              element={
                <UserGate
                  screenType={{
                    type: "dashboard",
                    screenName: "energymeter",
                  }}
                  allowedUsers={["superadmin", "admin"]}
                >
                  <Outlet />
                </UserGate>
              }
            >
              <Route index element={<EnergyMeterPage />} />
              <Route
                path={EnergyMeterRoutes.energyMeterDeviceList}
                element={<EnergyMeterDeviceListPage />}
              >
                <Route
                  path=":deviceId"
                  element={<EnergyMeterDeviceListPage />}
                />
              </Route>
            </Route>

            <Route
              path={DashboardRoutes.weatherStation}
              element={<WeatherStationDashboardPage />}
            />

            <Route
              path={DashboardRoutes.overallWaterLevel}
              element={
                <UserGate
                  screenType={{
                    type: "dashboard",
                    screenName: "waterlevel",
                  }}
                  allowedUsers={["superadmin", "admin"]}
                >
                  <Outlet />
                </UserGate>
              }
            >
              <Route index element={<WaterLevelDashboardPage />} />
              <Route
                path={DashboardRoutes.waterLevelBySublocation2Id(
                  ":sublocation2Id",
                )}
                element={
                  <WaterLevelLocationById detailDeviceType="water level" />
                }
              />
            </Route>

            <Route
              path={DashboardRoutes.oee}
              element={
                <UserGate
                  allowedUsers={[
                    "systemadmin",
                    "superadmin",
                    "admin",
                    "operator",
                  ]}
                  screenType={{
                    type: "dashboard",
                    screenName: "oee",
                  }}
                >
                  <Outlet />
                </UserGate>
              }
            >
              <Route index element={<OEEPage />} />
              <Route
                path={DashboardRoutes.oeeMachines}
                element={<OEEMachineDetail />}
              />
            </Route>

            <Route
              path={DashboardRoutes.envirobox}
              element={
                <UserGate
                  allowedUsers={["superadmin", "admin"]}
                  screenType={{
                    type: "dashboard",
                    screenName: "envirobox",
                  }}
                >
                  <Outlet />
                </UserGate>
              }
            >
              <Route index element={<EnviroboxPage />} />
              <Route
                path={DashboardRoutes.enviroboxDetailWithId(":deviceId")}
                element={<DetailEnviroboxPage />}
              />
            </Route>

            <Route
              path={DashboardRoutes.employeeTracker}
              element={
                <UserGate
                  allowedUsers={["superadmin", "admin"]}
                  screenType={{
                    type: "dashboard",
                    screenName: "employeetracker",
                  }}
                >
                  <Outlet />
                </UserGate>
              }
            >
              <Route index element={<EmployeeTrackerDashboardPage />} />
              <Route
                element={<EmployeeProfilePage />}
                path={DashboardRoutes.employeeProfile(":employeeId")}
              />
            </Route>

            {/* MANAGEMENT ROUTES */}
            <Route path={ListOfRoutes.management}>
              <Route
                path={ManagementRoutes.locations}
                element={
                  <UserGate
                    allowedUsers={[
                      "systemadmin",
                      "superadmin",
                      "admin",
                      "officer",
                    ]}
                    screenType={{
                      type: "management",
                      screenName: "location",
                    }}
                  >
                    <LocationPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.company}
                element={
                  <UserGate allowedUsers={["systemadmin"]}>
                    <CompanyPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.gateway}
                element={
                  <UserGate allowedUsers={["systemadmin"]}>
                    <GatewaysPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.sensortypes}
                element={
                  <UserGate allowedUsers={["systemadmin"]}>
                    <SensorTypePage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.devices}
                element={
                  <UserGate
                    allowedUsers={[
                      "systemadmin",
                      "superadmin",
                      "admin",
                      "officer",
                    ]}
                    screenType={{
                      type: "management",
                      screenName: "device",
                    }}
                  >
                    <DevicesPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.alerts}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "alert",
                    }}
                  >
                    <AlertPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.workorder}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "workorder",
                    }}
                  >
                    <WorkOrderPage />
                  </UserGate>
                }
              />

              <Route
                path={ManagementRoutes.shift}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "shift",
                    }}
                  >
                    <ShiftPage />
                  </UserGate>
                }
              />

              <Route
                path={ManagementRoutes.machine}
                element={
                  <UserGate
                    allowedUsers={[
                      "systemadmin",
                      "superadmin",
                      "admin",
                      "officer",
                    ]}
                    screenType={{
                      type: "management",
                      screenName: "machine",
                    }}
                  >
                    <MachineManagementPage />
                  </UserGate>
                }
              />

              <Route
                path={ManagementRoutes.area}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "area",
                    }}
                  >
                    <AreaManagementPage />
                  </UserGate>
                }
              />

              <Route
                path={ManagementRoutes.product}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "product",
                    }}
                  >
                    <ProductPage />
                  </UserGate>
                }
              />

              <Route
                path={ManagementRoutes.account}
                element={
                  <UserGate
                    allowedUsers={["systemadmin", "superadmin", "admin"]}
                    screenType={{
                      type: "management",
                      screenName: "account",
                    }}
                  >
                    <AccountPage />
                  </UserGate>
                }
              />

              <Route
                path={ManagementRoutes.employee}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "employee",
                    }}
                  >
                    <EmployeePage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.employeetag}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "employeetag",
                    }}
                  >
                    <TagPage />
                  </UserGate>
                }
              />
              <Route
                path={ManagementRoutes.employeetype}
                element={
                  <UserGate
                    allowedUsers={["superadmin", "admin", "officer"]}
                    screenType={{
                      type: "management",
                      screenName: "employeetype",
                    }}
                  >
                    <EmployeeTypePage />
                  </UserGate>
                }
              />
            </Route>
            {/* REPORT ROUTES */}
            <Route
              path={ListOfRoutes.reports}
              element={
                <UserGate
                  allowedUsers={["superadmin", "admin", "officer"]}
                  screenType={{
                    type: "other",
                    screenName: "report",
                  }}
                >
                  <Outlet />
                </UserGate>
              }
            >
              <Route index element={<ReportPage />} />
              <Route path={ReportRoutes.step1} element={<Step1 />} />
              <Route path={ReportRoutes.step2} element={<Step2 />} />
              <Route path={ReportRoutes.step3} element={<Step3 />} />
              <Route path={ReportRoutes.step4} element={<Step4 />} />
            </Route>
          </Route>
          <Route
            path={ListOfRoutes.machineDetails}
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              path=":id"
              element={
                <UserGate allowedUsers={["operator"]}>
                  <React.Suspense fallback={<LoaderPage />}>
                    <MachinePage />
                  </React.Suspense>
                </UserGate>
              }
            />
          </Route>
          <Route path={ListOfRoutes.landingPage} element={<LandingPage />} />
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <React.Suspense fallback={<LoaderPage />}>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route
            path={ListOfRoutes.verify}
            element={
              <React.Suspense fallback={<LoaderPage />}>
                <VerifyPage />
              </React.Suspense>
            }
          />
          {/* <Route path="/test" element={<TestPage />} /> */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <Toaster />
    </div>
  );
}

export default App;
