import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AProduct, ProductObj } from "../../api/productAPI";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const productStoreAPI = createApi({
  reducerPath: "productStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/oee/product`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ProductList"],
  endpoints: (builder) => {
    return {
      getProductList: builder.query<AProduct[], Partial<ProductObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AProduct[]>) =>
          response.data,
        providesTags: ["ProductList"],
      }),

      createProduct: builder.mutation<BackendDataShape<AProduct>, ProductObj>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ProductList"],
      }),

      updateProduct: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<ProductObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ProductList"],
      }),

      deleteProduct: builder.mutation<BackendDataShape<string>, { id: number }>(
        {
          query: ({ id }) => {
            return {
              url: `/delete/${id}`,
              method: "DELETE",
            };
          },
          invalidatesTags: ["ProductList"],
        },
      ),
    };
  },
});

export const {
  useGetProductListQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  util: { resetApiState: resetProductStoreAPI },
} = productStoreAPI;
