import { createUseStyles } from "react-jss";
import { BasicTheme, COLORS } from "../assets/theme";

const useNotificationStyles = createUseStyles((theme: BasicTheme) => ({
  container: {
    flex: 1,
    padding: "1rem 2rem",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
  },
  titleHeading: {
    fontWeight: 600,
    margin: "0.75rem 0",
    letterSpacing: "0.25px",
    fontSize: "18px",
  },
  childrenContainer: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    margin: "0 -2rem",
    padding: "0 2rem",
    minHeight: "100vh",
    paddingBottom: "2rem",
  },
  navContainer: {
    margin: "-1rem -2rem",
    padding: "1rem 2rem",
    marginBottom: 0,
    height: theme.barheight,
    flexBasis: theme.barheight,
    display: "flex",

    fontSize: "1.5rem",
    "& p": {
      margin: 0,
    },
  },
  controlContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    position: "relative",
  },
  actionBtn: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    border: 0,

    padding: ".5rem",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "50%",
    color: theme.black_1,
    "&:hover": {
      backgroundColor: theme.black_1,
      color: "white",
    },
  },
  logoutBtn: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    border: 0,
    padding: ".5rem",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "50%",
    color: theme.dangerColor,
    "&:hover": {
      backgroundColor: theme.dangerColor,
      color: "white",
    },
  },
  notificationBox: {
    position: "absolute",
    width: "296px",

    filter: "drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.25));",
    background: theme.white,
    right: "40px",
    top: "30px",
    zIndex: 100,
  },
  notificationItemBox: {
    overflowY: "auto",
    maxHeight: 260,
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      border: "3.6px solid transparent",
    },
  },
  notificationItem: {
    padding: "11.5px 14px",
    borderBottom: `1px solid ${COLORS.light_grey_1}`,
  },
  notificationItemText: {
    fontSize: "9px",
    color: COLORS.dark_grey_2,
  },
  notificationTime: {
    fontSize: "8px",
    textAlign: "right",
  },
  notificationItemWarning: {
    fontWeight: 600,
    color: COLORS.yellow,
    margin: "0 5px",
  },
  notificationIteDanger: {
    fontWeight: 600,
    color: COLORS.red,
    margin: "0 5px",
  },
  viewAllButton: {
    width: "100%",
    padding: "8px 0",
    textAlign: "center",
    position: "sticky",
    bottom: 0,
    cursor: "pointer",
    color: COLORS.blue_1,
    backgroundColor: COLORS.light_blue_1,
    fontSize: "9px",
    fontWeight: 400,
  },
}));

export default useNotificationStyles;
