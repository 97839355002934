import { BackendDataShape } from "../../api/config";
import {
  ADeviceGroup,
  ADeviceGroupWithColor,
  GroupDeviceArr,
  GroupObj,
} from "../../api/groupDeviceAPI";
import { COLORS } from "../../assets/theme";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { deviceStoreAPI } from "../deviceStore/deviceStoreAPI";

export const groupStoreAPI = deviceStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getDeviceGroups: builder.query<
        ADeviceGroupWithColor[],
        Partial<GroupObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/group/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        // transformResponse: (response: BackendDataShape<ADeviceGroup[]>) =>
        //   response.data,
        transformResponse: (response: BackendDataShape<ADeviceGroup[]>) =>
          response.data.map((val, idx) => {
            const groupColors = [
              COLORS.purple_1,
              COLORS.blue_1,
              COLORS.green_1,
              COLORS.dark_grey_1,
            ];

            return {
              ...val,
              groupColor: groupColors[idx % 4],
            };
          }),
        providesTags: ["GroupList"],
      }),

      createGroupDevice: builder.mutation<
        BackendDataShape<{ id: number }>,
        { name: string; show: boolean; selectedIds: number[] }
      >({
        query: (obj) => {
          return {
            url: "/group/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["GroupList", "DeviceList"],
      }),

      updateGroup: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * Group Id
           */
          id: number;
          data: Pick<ADeviceGroup, "name" | "companyId">;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/group/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["GroupList", "DeviceList"],
      }),

      deleteGroup: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * Group Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/group/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["GroupList", "DeviceList"],
      }),

      manageDevicesToGroups: builder.mutation<
        BackendDataShape<string[]>,
        GroupDeviceArr
      >({
        query: (obj) => {
          let url = "/group/manage";
          return {
            url,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["GroupList", "DeviceList"],
      }),
      addDevicesToGroupsArray: builder.mutation<
        BackendDataShape<string[]>,
        GroupDeviceArr
      >({
        query: (obj) => {
          let url = "/group/adddevicearray";
          return {
            url,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["GroupList", "DeviceList"],
      }),

      removeDevicesToGroups: builder.mutation<
        BackendDataShape<string[]>,
        GroupDeviceArr
      >({
        query: (obj) => {
          let url = "/group/removedevicearray";
          return {
            url,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["GroupList", "DeviceList"],
      }),
    };
  },
});

export const {
  useGetDeviceGroupsQuery,
  useManageDevicesToGroupsMutation,
  useAddDevicesToGroupsArrayMutation,
  useRemoveDevicesToGroupsMutation,
  useCreateGroupDeviceMutation,
  util: { resetApiState: resetDeviceGroupStoreAPI },
} = groupStoreAPI;
