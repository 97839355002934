import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
// import { ASensortype, SensortypeObj } from "../../api/sensorAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  ASensortype,
  CreateSensortypeObj,
} from "../../types/endpoints/sensor/sensor";

export const sensortypeStoreAPI = createApi({
  reducerPath: "sensortypeStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/sensortype`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SensortypeList", "AlertRelations"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getSensorTypes: builder.query<
        BackendDataShape<ASensortype[]>,
        Partial<CreateSensortypeObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ url, obj });
          return {
            url,
            method: "GET",
          };
        },
        providesTags: (res, err, { id }) => [{ type: "SensortypeList", id }],
        // invalidatesTags: ["User"],
      }),
    };
  },
});

export const {
  useGetSensorTypesQuery,
  util: { resetApiState: resetSensortypeStoreAPI },
} = sensortypeStoreAPI;
