// import { injectable } from "react-magnetic-di/macro";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import { MockUserData } from "../mocks";
import { AppDispatch, RootState } from "../store";
import useCompanyValue from "./useCompanyValue";
import useDeviceValue from "./useDeviceValue";
import useLocationValue from "./useLocationValue";
import useGatewayValue from "./useGatewayValue";
import { useUserTypeValue, useUserOpts } from "./useUserTypeValue";

export const useAppDispatch = () => {
  return useDispatch<AppDispatch>();
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// const MockState: RootState = {
//   sidebarStore: {
//     quickboardExpand: false,
//     sidebarExpand: true,
//   },
//   deviceStore: {
//     deviceArr: [],
//   },
//   userStore: {
//     userData: MockUserData,
//   },
//   gatewayStore: {
//     fetchStatus: "idle",
//   },
//   companyStore: {
//     companyArr: [],
//   },
//   locationStore: {
//     locationArr: [],
//   },
// };

// export const MockUseAppSelector: TypedUseSelectorHook<RootState> = (fun) => {
//   return fun(MockState);
// };

// export const MockUseAppSelectorDI = injectable(
//   useAppSelector,
//   MockUseAppSelector,
// );

// export const MockUseAppDispatchDI = injectable(useAppDispatch, () => {});

export {
  useCompanyValue,
  useDeviceValue,
  useLocationValue,
  useGatewayValue,
  useUserTypeValue,
  useUserOpts,
};
