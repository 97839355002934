import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { ASensor, GetSensorObj } from "../../types/endpoints/sensor/sensor";

export const sensorStoreAPI = createApi({
  reducerPath: "sensorStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/sensor`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["SensorList"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getSensor: builder.query<ASensor[], GetSensorObj>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ASensor[]>) =>
          response.data,
        providesTags: (result) =>
          result
            ? [
                ...result.map(
                  ({ id }) => ({ type: "SensorList", id } as const),
                ),
                { type: "SensorList", id: "LIST" },
              ]
            : [{ type: "SensorList", id: "LIST" }],
      }),
    };
  },
});

export const {
  useGetSensorQuery,
  util: { resetApiState: resetSensorStoreAPI },
} = sensorStoreAPI;
