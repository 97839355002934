import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AShift } from "../../api/shiftAPI";

export interface shiftState {
  shiftArr: AShift[];
}

const initialState: shiftState = {
  shiftArr: [],
};

export const shiftSlice = createSlice({
  name: "SHIFT_SLICE",
  initialState,
  reducers: {
    storeShift: (state, payload: PayloadAction<AShift[]>) => {
      state.shiftArr = payload.payload;
    },
    clearStore: (state) => {
      state = initialState;
    },
  },
});

export default shiftSlice.reducer;
export const { storeShift, clearStore: resetShiftStore } = shiftSlice.actions;
