import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AShift, ShiftObj } from "../../api/shiftAPI";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const shiftStoreAPI = createApi({
  reducerPath: "shiftStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/shift`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ShiftList"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getShift: builder.query<AShift[], Partial<ShiftObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          // console.log({ updateUrl: url });
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AShift[]>) =>
          response.data,
        providesTags: ["ShiftList"],
      }),

      createShift: builder.mutation<BackendDataShape<AShift>, ShiftObj>({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["ShiftList"],
      }),

      updateShift: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * gateway Id
           */
          id: number;
          data: Partial<ShiftObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ShiftList"],
      }),

      deleteShift: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * shift Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["ShiftList"],
      }),
    };
  },
});

export const {
  useCreateShiftMutation,
  useDeleteShiftMutation,
  useGetShiftQuery,
  useUpdateShiftMutation,
  util: { resetApiState: resetShiftStoreAPI },
} = shiftStoreAPI;
