import { CircularProgress } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CookieMonster from "../api/CookieMonster";
import { APermissionList } from "../api/moduleAPI";
import { ADashboardPermission } from "../api/permissionAPI";
import {
  employeeTrackerWebp,
  energymeterWebp,
  enviroboxWebp,
  oeeliveWebp,
  oeeWebp,
  waterlevelWebp,
} from "../assets/images";
import { COLORS, mq } from "../assets/theme";
import PageContainer from "../components/PageContainer";
import { DashboardRoutes, ListOfRoutes } from "../config/nav";
import useUsertype from "../hooks/auth/useUsertype";
import useGetCompanyPermissions from "../hooks/company/useGetCompanyPermissions";
import { useGetMyPermissionQuery } from "../store/userStore/userStoreAPI";
import { motion } from "framer-motion";
import { fadeInUp, stagger } from "../config/transitionProps";
import welcomingPageDescription from "../config/welcomingPageDescription.json";
type Props = {};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${mq[4]} {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: 12px;
  grid-column-gap: 24px;
`;

export const OverlayImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  opacity: 0;
  background-image: linear-gradient(
    0deg,
    rgba(0, 18, 40, 0.85),
    rgba(0, 18, 40, 0.85)
  );
  border-radius: 4px;
`;

export const StyledCardImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 240px;
  overflow: hidden;
  transition: all 0.2s;
`;

export const OverlayTextTitle = styled.div`
  position: absolute;
  top: 70%;
  left: 10%;
  text-overflow: ellipsis;
  font-size: 16px;
  white-space: normal;
  ${mq[1]} {
    top: 80%;
    left: 5%;
    font-size: 20px;
  }
  ${mq[4]} {
    top: 80%;
    left: 5%;
    font-size: 20px;
  }
  text-align: center;
  color: white;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 600;
  opacity: 1;
`;

export const OverlayBackgroundNonImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  opacity: 1;
  background-image: linear-gradient(
    to top,
    rgba(0, 18, 40, 0.5),
    rgba(0, 18, 40, 0.12)
  );

  cursor: pointer;
  border-radius: 4px;
`;

export const StyledImageContainer = styled.div`
  position: relative;
  width: auto;
  height: 240px;
  border-radius: 4px;
  margin-bottom: 1.2rem;
  border-radius: 1rem;
  overflow: hidden;

  :hover ${OverlayImage} {
    opacity: 0.95;
  }
  :hover ${OverlayTextTitle} {
    opacity: 0;
  }
  :hover ${OverlayBackgroundNonImage} {
    opacity: 0;
  }
`;

export const OverlayText = styled.div`
  color: white;
  font-size: 14px;
  ${mq[2]} {
    font-size: 16px;
  }
  ${mq[3]} {
    font-size: 24px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const OverlayTextDescription = styled.p`
  width: 100%;
  margin-top: 8px;
  font-size: 10px;
  margin-bottom: 0;
  ${mq[1]} {
    font-size: 12px;
    width: 70vh;
  }
`;

const allPermissions: APermissionList = {
  dashboard: [
    "employeetracker",
    "energymeter",
    "envirobox",
    "oee",
    "oeelive",
    "waterlevel",
  ],
  other: [],
  management: [],
};

const HomePage = (props: Props) => {
  const usertype = useUsertype();
  const { data: permissions, isLoading: isLoadingPermissions } =
    useGetMyPermissionQuery(
      {},
      {
        skip:
          !usertype || usertype === "superadmin" || usertype === "systemadmin",
      },
    );
  const navigate = useNavigate();

  const {
    permission: companyPermissions,
    isLoading: isLoadingCompanyPermission,
  } = useGetCompanyPermissions({
    args: {
      id: CookieMonster.loadCookie("companyId"),
    },
  });

  const myPermissions = useMemo(() => {
    if (!permissions) return;
    return permissions.permissions;
  }, [permissions]);

  const capitalizeWords = (arrString: string[]) => {
    return arrString.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  };

  const availableCards: {
    [key in keyof ADashboardPermission]: boolean;
  } = React.useMemo(() => {
    // default
    let obj: { [key in keyof ADashboardPermission]: boolean } = {
      employeetracker: false,
      energymeter: false,
      envirobox: false,
      oee: false,
      oeelive: false,
      waterlevel: false,
    };
    switch (usertype) {
      case "systemadmin":
        Object.values(allPermissions.dashboard).forEach((key) => {
          obj[key] = false;
        });
        return obj;
      case "superadmin":
        Object.values(allPermissions.dashboard).forEach((key) => {
          obj[key] = companyPermissions?.dashboard.some(
            (val) => val === key && key !== "oeelive",
          );
        });
        return obj;
      case "operator":
        Object.values(allPermissions.dashboard).forEach((key) => {
          obj[key] =
            key === "oeelive" &&
            !!companyPermissions?.dashboard.some((val) => val === "oeelive") &&
            !!myPermissions?.dashboard.some((val) => val === "oeelive");
        });
        return obj;
      default:
        Object.values(allPermissions.dashboard).forEach((key) => {
          obj[key] =
            !!companyPermissions?.dashboard.some((val) => val === key) &&
            !!myPermissions?.dashboard.some((val) => val === key);
        });
        return obj;
      // return companyPermissions?.dashboard.some((val) => val === item);
    }
  }, [usertype, companyPermissions, myPermissions]);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <PageContainer
        withControl
        title={
          <span style={{ color: COLORS.black_3, fontWeight: 700 }}>
            Welcome to{" "}
            <span style={{ color: COLORS.blue_2 }}>Rapidsense Dashboard</span>
          </span>
        }
      >
        {isLoadingCompanyPermission || isLoadingPermissions ? (
          <CircularProgress
            size={24}
            color="inherit"
            style={{ color: COLORS.asphalt }}
            thickness={4}
          />
        ) : (
          <StyledGrid
            as={motion.div}
            initial="initial"
            animate="animate"
            variants={stagger}
          >
            {companyPermissions &&
              Object.values(allPermissions.dashboard)
                // .filter((item) => usertype !== "systemadmin")
                .sort((a, b) =>
                  availableCards[a] === availableCards[b]
                    ? 0
                    : availableCards[a]
                    ? -1
                    : 1,
                )
                .map((item) => {
                  let imageSwitch = "";
                  let defaultRoutes = "";
                  switch (item) {
                    case "energymeter":
                      imageSwitch = energymeterWebp;
                      defaultRoutes = DashboardRoutes.energyMeter;
                      break;
                    case "employeetracker":
                      imageSwitch = employeeTrackerWebp;
                      defaultRoutes = DashboardRoutes.employeeTracker;
                      break;
                    case "envirobox":
                      imageSwitch = enviroboxWebp;
                      defaultRoutes = DashboardRoutes.envirobox;
                      break;
                    case "waterlevel":
                      imageSwitch = waterlevelWebp;
                      defaultRoutes = DashboardRoutes.waterlevel;
                      break;
                    case "oee":
                      imageSwitch = oeeWebp;
                      defaultRoutes = DashboardRoutes.oee;
                      break;
                    case "oeelive":
                      imageSwitch = oeeliveWebp;
                      defaultRoutes = ListOfRoutes.machineDetails + "/35";
                      break;
                    default:
                      break;
                  }
                  let textImageSwitch = (item: keyof ADashboardPermission) => {
                    if (item === "oee") {
                      return (
                        <span>
                          OEE{" "}
                          <span style={{ fontWeight: 400 }}>
                            (Overall Equipment Effectiveness)
                          </span>
                        </span>
                      );
                    }
                    if (item === "energymeter") {
                      return <span>Energy Meter</span>;
                    }
                    if (item === "employeetracker") {
                      return <span>Employee Tracker</span>;
                    }
                    if (item === "oeelive") {
                      return <span>OEE Live Operator</span>;
                    } else {
                      return <span>{capitalizeWords(item.split(" "))}</span>;
                    }
                  };
                  let descriptionImageSwitch = (
                    item: keyof ADashboardPermission,
                  ) => {
                    switch (item) {
                      case "energymeter":
                        return welcomingPageDescription.energymeter;
                      case "employeetracker":
                        return welcomingPageDescription.employeetracker;
                      case "envirobox":
                        return welcomingPageDescription.envirobox;
                      case "waterlevel":
                        return welcomingPageDescription.waterlevel;
                      case "oee":
                        return welcomingPageDescription.oee;
                      case "oeelive":
                        return welcomingPageDescription.oeelive;
                      default:
                        return "";
                    }
                  };
                  return (
                    <StyledImageContainer
                      key={item}
                      onClick={() =>
                        availableCards[item] && navigate(defaultRoutes)
                      }
                      style={{
                        filter: !availableCards[item] ? "grayscale(1)" : "",
                        cursor: !availableCards[item]
                          ? "not-allowed"
                          : "pointer",
                      }}
                      as={motion.div}
                    >
                      <StyledCardImage src={imageSwitch} />
                      <OverlayBackgroundNonImage
                        style={{
                          backgroundImage: !availableCards[item]
                            ? "linear-gradient( 360deg, #141519 0%, rgba(20, 21, 25, 0) 65.25%)"
                            : "",
                        }}
                      >
                        <OverlayTextTitle>
                          <span style={{ fontWeight: 600 }}>
                            {textImageSwitch(item)}
                          </span>
                        </OverlayTextTitle>
                      </OverlayBackgroundNonImage>
                      <OverlayImage>
                        <OverlayText>
                          <span style={{ fontWeight: 600 }}>
                            {textImageSwitch(item)}
                          </span>
                          <OverlayTextDescription>
                            {descriptionImageSwitch(item)}
                          </OverlayTextDescription>
                        </OverlayText>
                      </OverlayImage>
                    </StyledImageContainer>
                  );
                })}
          </StyledGrid>
        )}
      </PageContainer>
    </div>
  );
};

export default HomePage;
