import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "..";
import CookieMonster from "../../api/CookieMonster";
import {
  letUserLogin,
  LoginObj,
  UserDataWOToken,
} from "../../api/credentialsAPI";
import { ListOfRoutes } from "../../config/nav";
import { resetAlertStore } from "../../store/alertStore";
import { resetAlertStoreAPI } from "../../store/alertStore/alertStoreAPI";
import { resetCompanyStore } from "../../store/companyStore";
import { resetCompanyStoreAPI } from "../../store/companyStore/companyStoreAPI";
import { resetDashboardDetailStore } from "../../store/dashboardDetailStore";
import { resetDashboardEnviroboxAPI } from "../../store/dashboardEnviroboxStore/dashboardEnviroboxStoreAPI";
import { resetDashboardOEEStoreAPI } from "../../store/dashboardOEEStoreAPI/dashboardOEEStoreAPI";
import { resetDashboardStoreAPI } from "../../store/dashboardStore/dashboardStoreAPI";
import { resetDeviceGroupStoreAPI } from "../../store/deviceGroupStore/groupStoreAPI";
import { resetDeviceStore } from "../../store/deviceStore";
import { resetDeviceStoreAPI } from "../../store/deviceStore/deviceStoreAPI";
import { resetDevicetypeStoreAPI } from "../../store/devicetypeStore/devicetypeStoreAPI";
import { resetGatewayStoreAPI } from "../../store/gatewayStore/gatewayStoreAPI";
import { resetLocationStoreAPI } from "../../store/locationStore/locationStoreAPI";
import { resetGroupMachineStoreAPI } from "../../store/machineStore/groupMachineStoreAPI";
import { resetMachineStoreAPI } from "../../store/machineStore/machineStoreAPI";
import { resetProductStoreAPI } from "../../store/productStore/productStoreAPI";
import { resetReportStore } from "../../store/reportStore";
import { resetSensorStore } from "../../store/sensorStore";
import { resetSensorStoreAPI } from "../../store/sensorStore/sensorStoreAPI";
import { resetSensortypeStoreAPI } from "../../store/sensorTypeStore/sensortypeStoreAPI";
import { resetShiftStore } from "../../store/shiftStore";
import { resetShiftStoreAPI } from "../../store/shiftStore/shiftStoreAPI";
import { removeUserData, storeUserData } from "../../store/userStore";
import { resetWorkorderStoreAPI } from "../../store/workorderStore/workorderStoreAPI";
import { resetMachineOEEStoreAPI } from "../../store/machineDashboardOEEStore/machineOEEStoreAPI";
import { resetEnergymeterStoreAPI } from "../../store/energymeterStore/energymeterAPI";
import { resetDeviceSensorRelationAPI } from "../../store/deviceStore/deviceSensorRelationStoreAPI";
import { resetNotificationAPI } from "../../store/notificationStore/notificationStoreAPI";
import { resetScheduleStoreAPI } from "../../store/scheduleStore/scheduleStoreAPI";
import { resetWaterLevelApi } from "../../store/waterlevelStore/waterlevelStoreAPI";
import { resetUserStoreAPI } from "../../store/userStore/userStoreAPI";
import { resetModuleStore } from "../../store/moduleStore/moduleStoreAPI";
import { resetUsertypeStoreAPI } from "../../store/usertypeStore/usertypeStoreAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { BackendDataShape, CredsLinks } from "../../api/config";
import axios from "axios";
import { resetEmployeeStoreAPI } from "../../store/employeeStore/employeeStoreAPI";
import { resetEmployeeTypeStoreAPI } from "../../store/employeeTypeStore/employeeTypeStoreAPI";
import { resetEmployeeTagStoreAPI } from "../../store/employeeTagStore/employeeTagStoreAPI";
import { resetLocationAreaStoreAPI } from "../../store/locationStore/locationareaStore";

export const useClearAllCache = () => {
  const dispatch = useAppDispatch();

  return () => {
    // Tambahkan secara abjad
    dispatch(resetAlertStore());
    dispatch(resetAlertStoreAPI());
    dispatch(resetCompanyStore());
    dispatch(resetCompanyStoreAPI());
    dispatch(resetDashboardDetailStore());
    dispatch(resetDashboardStoreAPI());
    dispatch(resetDashboardOEEStoreAPI());
    dispatch(resetDashboardEnviroboxAPI());
    dispatch(resetDevicetypeStoreAPI());
    dispatch(resetDeviceStore());
    dispatch(resetDeviceStoreAPI());
    dispatch(resetDeviceSensorRelationAPI());
    dispatch(resetDeviceGroupStoreAPI());
    dispatch(resetEnergymeterStoreAPI());
    dispatch(resetGatewayStoreAPI());
    dispatch(resetGroupMachineStoreAPI());
    dispatch(resetLocationStoreAPI());
    dispatch(resetLocationStoreAPI());
    dispatch(resetMachineOEEStoreAPI());
    dispatch(resetMachineStoreAPI());
    dispatch(resetModuleStore());
    dispatch(resetNotificationAPI());
    dispatch(resetProductStoreAPI());
    dispatch(resetReportStore());
    dispatch(resetScheduleStoreAPI());
    dispatch(resetSensorStore());
    dispatch(resetSensorStoreAPI());
    dispatch(resetSensortypeStoreAPI());
    dispatch(resetShiftStore());
    dispatch(resetShiftStoreAPI());
    dispatch(resetWaterLevelApi());
    dispatch(resetWorkorderStoreAPI());
    dispatch(resetUserStoreAPI());
    dispatch(resetUsertypeStoreAPI());
    dispatch(resetEmployeeStoreAPI());
    dispatch(resetEmployeeTypeStoreAPI());
    dispatch(resetEmployeeTagStoreAPI());
    dispatch(resetLocationAreaStoreAPI());
  };
};

export const useLogout = () => {
  const clearAllCache = useClearAllCache();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return () => {
    CookieMonster.removeAllTheseCookies([
      "usertype",
      "usertypeId",
      "jwt",
      "companyId",
      "username",
    ]);
    clearAllCache();
    dispatch(removeUserData());
    navigate(ListOfRoutes.loginpage);
    localStorage.clear();
  };
};

export interface GetUserDataReqObj {
  id?: number;
  username?: string;
  email?: string;
}

export function getUser(obj: GetUserDataReqObj) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${CredsLinks.user(params)}`;

  return axios.get<BackendDataShape<UserDataWOToken[]>>(url, {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  });
}

export const useLogin = () => {
  return async (obj?: LoginObj) => {
    const {
      data: { data },
    } = await letUserLogin(obj);
    const { jwt, exp, iat, ...rest } = data;

    // console.log({ rest, jwt });

    CookieMonster.saveAllTheseCookies(
      {
        jwt,
        usertype: rest.usertypeName,
        companyId: rest.companyId,
        username: rest.username,
        usertypeId: rest.usertypeId,
      },
      { expires: new Date(exp * 1000) },
    );
    return rest;
  };
};
