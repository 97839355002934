import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  ADeviceBasic,
  ADeviceDetailSublocationMachineWithMap,
  BindAccountObj,
  DeviceObj,
} from "../../api/deviceAPI";
import { GetDeviceObj } from "../../types/endpoints/device/device";

export const deviceStoreAPI = createApi({
  reducerPath: "deviceStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/device`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["DeviceList", "GroupList", "DeviceRelationList"],
  endpoints: (builder) => {
    return {
      /**
       * query<ShapeOfReturn, ShapeOfArgs>
       */
      getDevices: builder.query<
        ADeviceDetailSublocationMachineWithMap[],
        GetDeviceObj
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (
          response: BackendDataShape<ADeviceDetailSublocationMachineWithMap[]>,
        ) => response.data,
        providesTags: ["DeviceList"],
      }),

      createDevice: builder.mutation<BackendDataShape<ADeviceBasic>, DeviceObj>(
        {
          query: (obj) => {
            return {
              url: "/create",
              body: obj,
              method: "POST",
            };
          },
          invalidatesTags: ["DeviceList"],
        },
      ),

      updateDevice: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * device Id
           */
          id: number;
          data: Partial<DeviceObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["DeviceList"],
      }),

      deleteDevice: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * device Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["DeviceList"],
      }),
      addDeviceMap: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * device Id
           */
          id: number;
          file: File | null | FormData;
        }
      >({
        query: ({ id, file }) => {
          return {
            body: file,
            url: `/addmap/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["DeviceList"],
      }),
      deleteDeviceMap: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * device Id
           */
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/deletemap/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["DeviceList"],
      }),
      updateStatusDevice: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * device Id
           */
          id: number;
          data: URLSearchParams | string;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };
        },
        invalidatesTags: ["DeviceList"],
      }),
      bindUserDevice: builder.mutation<
        BackendDataShape<ADeviceBasic>,
        BindAccountObj
      >({
        query: (obj) => {
          return {
            url: "/user/save",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["DeviceList"],
      }),
    };
  },
});

export const {
  useGetDevicesQuery,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
  useAddDeviceMapMutation,
  useUpdateStatusDeviceMutation,
  useDeleteDeviceMapMutation,
  useBindUserDeviceMutation,
  util: { resetApiState: resetDeviceStoreAPI },
} = deviceStoreAPI;
