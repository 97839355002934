import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction } from "react";
import { ValueInterval } from "../../api/__deprecated_api/sensorAPI";

export function convertDashTextToProper(str: string): string {
  let strArr = str.split("_");
  return strArr
    .map((val) => {
      let [a, ...rest] = val.split("");
      return `${a.toUpperCase()}${rest.map((a) => a).join("")}`;
    })
    .join(" ");
}

export const numberWithDots = (num: number) =>
  num
    .toFixed()
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export type ChartAcceptableTimeUnit =
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export const convertResponseImageUrlState = (
  setState: Dispatch<SetStateAction<string>>,
  res: AxiosResponse<any, any>,
) => {
  let image = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  setState(`data:${res.headers["content-type"].toLowerCase()};base64,${image}`);
};

export const convertAxiosResponseToString = (
  res: AxiosResponse<any, any>,
): string => {
  let image = btoa(
    new Uint8Array(res.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  return `data:${res.headers["content-type"].toLowerCase()};base64,${image}`;
};

export function extractTimeFormat(
  interval: ValueInterval,
): ChartAcceptableTimeUnit {
  let text = interval.match(/([a-z]+)/);
  if (text) {
    let time = text[0];
    switch (time) {
      case "m":
        return "minute";
      case "h":
        return "hour";
      case "d":
        console.log({ interval }, "day");
        return "day";
      case "w":
        return "week";
      case "mo":
        return "month";
      case "y":
        return "year";
      default:
        throw new Error("invalid interval passed!");
    }
  } else {
    throw new Error("invalid interval passed!");
  }
}

export const idrCurrencyFormatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
});

export const idNumberFormatter = new Intl.NumberFormat("id-ID", {
  // style
});

/**
 *
 * @param number the number to round
 * @param n decimal point to round
 */
export function roundNumber(number: number, n: number) {
  if (n < 0) return number;
  let rounding10 = Math.pow(10, n);
  return Math.round(number * rounding10) / rounding10;
}

const basicColors: string[] = [
  "#11B911",
  "#C71585",
  "#00A6DD",
  "#7FB800",
  "#0D2C54",
  "#E10000",
  "#864BFF",
  "#17C37B",
];

export function palleteGenerator(numberOfColors: number = 5): string[] {
  if (numberOfColors <= 0 || typeof numberOfColors !== "number") {
    return [];
  }

  if (numberOfColors < basicColors.length) {
    return basicColors.slice(0, numberOfColors);
  } else {
    let remainingColors = palleteGenerator(numberOfColors - basicColors.length);
    if (remainingColors.length) {
      return basicColors.slice().concat(remainingColors);
    } else return basicColors.slice();
  }
}

export function convertToEncodedURL(obj: { [key: string]: any }) {
  let formBody = [];
  for (const property in obj) {
    if (typeof obj[property] === "undefined") continue;
    const key = encodeURIComponent(property);
    const value = encodeURIComponent(obj[property]);
    formBody.push(`${key}=${value}`);
  }
  return formBody.join("&");
}

export function pickRandomFromArray<T extends any>(arr: Array<T>) {
  let i = Math.floor(Math.random() * arr.length);
  return arr[i];
}

export const BackendDateFormat = "yyyy-MM-dd HH:mm:ss";

export const constructProperName = (
  firstname?: string,
  lastname?: string,
): string => {
  let name = `${firstname ?? ""} ${lastname ?? ""}`.trim();
  if (name.length) return name;
  return "-";
};

export const onlyNumber = (str: string) => {
  const onlyNumbers = /^[0-9]+$/;
  const testNumber = onlyNumbers.test(str);
  if (testNumber) return true;
  return false;
};

export const addAlphatoHexColor = (color: string, opacity: number): string => {
  const newOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + newOpacity.toString(16).toUpperCase();
};
