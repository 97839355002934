import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import {
  ALocationArea,
  ALocationAreaWithColor,
  LocationAreaObj,
} from "../../api/LocationAPI";
import { COLORS } from "../../assets/theme";

export const locationareaStoreAPI = createApi({
  reducerPath: "locationareaStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/location/locationarea`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["LocationAreaList"],
  endpoints: (builder) => {
    return {
      getLocationArea: builder.query<
        ALocationAreaWithColor[],
        Partial<ALocationArea>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<ALocationArea[]>) =>
          response.data.map((val, idx) => {
            const areaColors = [
              COLORS.purple_1,
              COLORS.blue_1,
              COLORS.green_1,
              COLORS.dark_grey_1,
              COLORS.asphalt,
              COLORS.brown_1,
              COLORS.cyan,
              COLORS.light_green,
              COLORS.light_yellow,
              COLORS.red_2,
              COLORS.purple_2,
            ];

            return {
              ...val,
              color: areaColors[idx % areaColors.length],
            };
          }),
        providesTags: ["LocationAreaList"],
      }),
      createLocationArea: builder.mutation<
        BackendDataShape<ALocationArea>,
        LocationAreaObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["LocationAreaList"],
      }),
      updateLocationArea: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<LocationAreaObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["LocationAreaList"],
      }),

      deleteLocationArea: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["LocationAreaList"],
      }),
    };
  },
});

export const {
  useCreateLocationAreaMutation,
  useDeleteLocationAreaMutation,
  useGetLocationAreaQuery,
  useUpdateLocationAreaMutation,
  util: { resetApiState: resetLocationAreaStoreAPI },
} = locationareaStoreAPI;
