import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ADeviceWithDetails } from "../../api/deviceAPI";

export interface deviceState {
  deviceArr: ADeviceWithDetails[];
}

const initialState: deviceState = {
  deviceArr: [],
};

export const deviceSlice = createSlice({
  name: "DEVICE_SLICE",
  initialState,
  reducers: {
    storeDevices: (state, payload: PayloadAction<ADeviceWithDetails[]>) => {
      state.deviceArr = payload.payload;
    },
    clearState: () => initialState,
    // clearState: (state) => {
    //   Object.assign(state, initialState);
    // },
  },
});

export default deviceSlice.reducer;
export const { storeDevices, clearState: resetDeviceStore } =
  deviceSlice.actions;
