import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { ADeviceDetailsWithSensorAlert } from "../../api/deviceAPI";

import {
  AWaterlevelBasic,
  SensorlogWaterlevelReqObj,
} from "../../api/waterlevelAPI";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const waterlevelStoreAPI = createApi({
  reducerPath: "waterlevelStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["WaterlevelList", "DetailWaterlevel", "SensorlogWaterlevelList"],
  endpoints: (builder) => {
    return {
      getWaterLevelList: builder.query<AWaterlevelBasic, object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/waterlevel" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["WaterlevelList"],
        transformResponse: (response: BackendDataShape<AWaterlevelBasic>) => {
          return response.data;
        },
      }),
      getDetailWaterLevel: builder.query<
        ADeviceDetailsWithSensorAlert[],
        { deviceId: number }
      >({
        query: ({ deviceId }) => {
          const url = `/device/waterlevel/${deviceId}`;
          return {
            url: url,
            method: "GET",
          };
        },
        providesTags: ["DetailWaterlevel"],
        transformResponse: (
          response: BackendDataShape<ADeviceDetailsWithSensorAlert[]>,
        ) => {
          return response.data;
        },
      }),
      getSensorlogWaterLevelList: builder.query<
        ADeviceDetailsWithSensorAlert[],
        SensorlogWaterlevelReqObj
      >({
        query: (obj) => {
          return {
            body: obj,
            url: "/sensorlog/waterlevel",
            method: "POST",
          };
        },
        providesTags: (_, __, obj) => {
          // @ts-ignore
          let id: string | undefined = obj.period ? obj.period : undefined;
          return [
            "SensorlogWaterlevelList",
            { type: "SensorlogWaterlevelList", id },
          ];
        },
        transformResponse: (
          res: BackendDataShape<ADeviceDetailsWithSensorAlert[]>,
        ) => res.data,
      }),
    };
  },
});

export const {
  useGetWaterLevelListQuery,
  useGetDetailWaterLevelQuery,
  useGetSensorlogWaterLevelListQuery,
  util: { resetApiState: resetWaterLevelApi },
} = waterlevelStoreAPI;
