import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface sidebarState {
  sidebarExpand: boolean;
  quickboardExpand: boolean;
}

const initialState: sidebarState = {
  sidebarExpand: false,
  quickboardExpand: false,
};

export const sidebarSlice = createSlice({
  name: "SIDEBAR_SLICE",
  initialState,
  reducers: {
    toggleSidebar: (state, payload: PayloadAction<boolean | undefined>) => {
      if (payload.payload) {
        state.sidebarExpand = payload.payload;
      } else {
        state.sidebarExpand = !state.sidebarExpand;
      }
    },
    toggleQuickboard: (state, payload: PayloadAction<boolean | undefined>) => {
      if (payload.payload) {
        state.quickboardExpand = payload.payload;
      } else {
        state.quickboardExpand = !state.quickboardExpand;
      }
    },
  },
});

export default sidebarSlice.reducer;
export const { toggleQuickboard, toggleSidebar } = sidebarSlice.actions;
