import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AWorkOrder, WorkOrderObj } from "../../api/workorderAPI";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";

export const workorderStoreAPI = createApi({
  reducerPath: "workorderStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/oee/workorder`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["WorkOrderList"],
  endpoints: (builder) => {
    return {
      getWorkOrder: builder.query<AWorkOrder[], Partial<WorkOrderObj>>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AWorkOrder[]>) =>
          response.data,
        providesTags: ["WorkOrderList"],
      }),
      createWorkOrder: builder.mutation<
        BackendDataShape<AWorkOrder>,
        WorkOrderObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["WorkOrderList"],
      }),

      updateWorkOrder: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<WorkOrderObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WorkOrderList"],
      }),

      deleteWorkOrder: builder.mutation<
        BackendDataShape<string>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["WorkOrderList"],
      }),

      updateRunningStatus: builder.mutation<
        BackendDataShape<string>,
        { workorderId: number }
      >({
        query: ({ workorderId }) => {
          return {
            url: "/run",
            method: "POST",
            body: { workorderId },
          };
        },
        invalidatesTags: ["WorkOrderList"],
      }),

      updatePausedStatus: builder.mutation<
        BackendDataShape<string>,
        { workorderId: number }
      >({
        query: ({ workorderId }) => {
          return {
            url: "/pause",
            method: "POST",
            body: { workorderId },
          };
        },
        invalidatesTags: ["WorkOrderList"],
      }),

      updateStopStatus: builder.mutation<
        BackendDataShape<string>,
        { workorderId: number }
      >({
        query: ({ workorderId }) => {
          return {
            url: "/close",
            method: "POST",
            body: { workorderId },
          };
        },
        invalidatesTags: ["WorkOrderList"],
      }),
      getWorkOrderDetails: builder.query<
        BackendDataShape<AWorkOrder[]>,
        { id: number }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          let url = "/find" + params;
          console.log({ url });
          return {
            url,
            method: "GET",
          };
        },
        providesTags: (res, error, { id }) => [
          { type: "WorkOrderList", id: id },
        ],
      }),
    };
  },
});

export const {
  useGetWorkOrderQuery,
  useGetWorkOrderDetailsQuery,
  useCreateWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useDeleteWorkOrderMutation,
  useUpdatePausedStatusMutation,
  useUpdateRunningStatusMutation,
  useUpdateStopStatusMutation,
  util: { resetApiState: resetWorkorderStoreAPI },
} = workorderStoreAPI;
