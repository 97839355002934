import React, { CSSProperties } from "react";
import { IconType } from "react-icons";
import { BsChevronDown } from "react-icons/bs";
import { createUseStyles } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import CookieMonster from "../../api/CookieMonster";
import { KnownUsertype } from "../../api/credentialsAPI/usertype";
import {
  ADashboardPermission,
  AManagementPermission,
  AOtherPermission,
} from "../../api/permissionAPI";
import { BasicTheme, COLORS } from "../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useUsertype, {
  checkIfUsertypeAllowed,
} from "../../hooks/auth/useUsertype";
import useGetCompanyPermissions from "../../hooks/company/useGetCompanyPermissions";
import useMatchKiddo from "../../hooks/useMatchKiddo";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useGetMyPermissionQuery } from "../../store/userStore/userStoreAPI";
import { addAlphatoHexColor } from "../../assets/usefulFunctions";

export interface KiddoElement {
  title: string;
  Icon?: IconType;
  shrinkTitle?: string;
  /**
   * if `undefined` or has length === 0, it will be allowed for everyone
   */
  allowedUsers?: KnownUsertype[];
  kiddoURL?: string;

  screenType?:
    | keyof ADashboardPermission
    | keyof AManagementPermission
    | keyof AOtherPermission;
}

export interface Props {
  title?: string;

  /**
   * if there is kiddo, clicking on parent item wont do any navigation
   */
  parentURL?: string;
  /**
   * if `undefined` or has length === 0, it will be allowed for everyone
   */
  allowedUsers?: KnownUsertype[];
  Icon?: IconType;
  kiddos?: KiddoElement[];
  screenType?:
    | keyof ADashboardPermission
    | keyof AManagementPermission
    | keyof AOtherPermission;
}

const activeMatchBorder: CSSProperties = {
  border: "10px solid",
  borderImageSlice: 1,
  borderWidth: 5,
  borderImageSource: COLORS.gradient_blue,
  borderLeft: 0,
  borderTop: 0,
  borderBottom: 0,
};

const useStyles = createUseStyles((theme: BasicTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    buttonStyle: {
      display: "flex",
      border: 0,
      alignItems: "center",
      padding: "0 1rem",
      backgroundColor: COLORS.black_1,
      color: "white",
      transition: "all .2s",
      "&.match": {
        backgroundColor: addAlphatoHexColor(COLORS.blue_1, 0.15),
        ...activeMatchBorder,
      },
      "&:hover": {
        backgroundColor: theme.sidebarBackgroundColor,
        color: "white",
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&.shrink": {
        justifyContent: "space-between",
        paddingBottom: "10px",
        paddingTop: "10px",
      },
      textDecoration: "none",
    },
    buttonExpandStyle: {
      display: "flex",
      border: 0,
      alignItems: "center",
      padding: "0 1rem",
      backgroundColor: COLORS.black_1,
      color: "white",
      transition: "all .2s",
      "&:hover": {
        backgroundColor: theme.sidebarBackgroundColor,
      },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
      "&.shrink": {
        justifyContent: "space-between",
        paddingBottom: "10px",
        paddingTop: "10px",
      },
    },
    title: {
      flex: 1,
      margin: "1rem 0",
      textAlign: "left",
      overflowX: "hidden",
      "&.shrink": {
        textAlign: "center",
      },
    },
    chevron: {
      transform: "rotate(0deg)",
      transition: "transform .2s ease-in-out",
      "&.open": {
        transform: "rotate(-180deg)",
      },
    },
    kiddosContainer: {
      maxHeight: 0,
      transition: "all .3s",
      overflow: "hidden",
      "&.open": {
        maxHeight: "2000px", // need to be decided, scroll or view all?
      },
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    aKiddo: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      padding: "0.35rem 1rem",
      backgroundColor: COLORS.black_1,
      color: "white",
      position: "relative",
      transition: "all .2s",
      textDecoration: "none",
      "&:hover": {
        backgroundColor: theme.sidebarBackgroundColor,
        color: "white",
      },
      "&.match": {
        backgroundColor: addAlphatoHexColor(COLORS.blue_1, 0.15),
        ...activeMatchBorder,
      },
    },
  };
});

const SidebarItem: React.FunctionComponent<Props> = (props) => {
  const { title, parentURL, Icon, allowedUsers, kiddos, screenType } = props;
  const usertype = useUsertype() ?? "";
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const { match, matchKiddo } = useMatchKiddo({ kiddos, parentURL });
  const { permission: companyPermissions } = useGetCompanyPermissions({
    args: {
      id: CookieMonster.loadCookie("companyId"),
    },
    options: {
      skip: usertype === "systemadmin",
    },
  });

  const { data: userPermission } = useGetMyPermissionQuery(
    {},
    {
      skip:
        !usertype || usertype === "superadmin" || usertype === "systemadmin",
    },
  );

  const checkIfAllowedInCompany = React.useCallback(
    (screen: string): boolean => {
      let usedScreen: any = screen; // to any to disable typing
      // return true;
      const { dashboard, management: mananagement, other } = companyPermissions;
      return (
        !!dashboard.find((k) => k === usedScreen) ||
        !!mananagement.find((k) => k === usedScreen) ||
        !!other.find((k) => k === usedScreen)
      );
    },
    [companyPermissions],
  );

  const checkIfAllowedToUser = React.useCallback(
    (screen: string): boolean => {
      let usedScreen: any = screen;

      if (!userPermission) return false;
      const {
        permissions: { dashboard, management: mananagement, other },
      } = userPermission;

      return (
        !!dashboard.find((k) => k === usedScreen) ||
        !!mananagement.find((k) => k === usedScreen) ||
        !!other.find((k) => k === usedScreen)
      );
    },
    [userPermission],
  );

  const isExpanded = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );
  const isGreaterThan768 = useMediaQuery("(min-width: 768px)");
  const dispatch = useAppDispatch();

  const listedKiddos = React.useMemo(() => {
    if (kiddos && kiddos.length) {
      let allowedKiddos: KiddoElement[] = [];
      kiddos.forEach((kid) => {
        const { screenType, title } = kid;
        let isScreenAllowed = true;
        switch (usertype) {
          case "systemadmin":
            isScreenAllowed = true;
            break;
          case "superadmin":
            isScreenAllowed = screenType
              ? checkIfAllowedInCompany(screenType)
              : true;
            break;
          default:
            isScreenAllowed = screenType
              ? checkIfAllowedInCompany(screenType) &&
                checkIfAllowedToUser(screenType)
              : true;
            break;
        }
        const isKidAllowed = checkIfUsertypeAllowed(usertype, kid.allowedUsers);
        if (isKidAllowed && isScreenAllowed) {
          allowedKiddos.push(kid);
        }
      });
      return allowedKiddos;
    }
    return undefined;
  }, [kiddos, usertype, checkIfAllowedInCompany, checkIfAllowedToUser]);

  const allowed = React.useMemo(() => {
    let isScreenAllowed = true;
    switch (usertype) {
      case "systemadmin":
        isScreenAllowed = true;
        break;
      case "superadmin":
        isScreenAllowed = screenType
          ? checkIfAllowedInCompany(screenType)
          : true;
        break;
      default:
        isScreenAllowed = screenType
          ? checkIfAllowedInCompany(screenType) &&
            checkIfAllowedToUser(screenType)
          : true;
        break;
    }
    const isParentAllowed =
      checkIfUsertypeAllowed(usertype, allowedUsers) && isScreenAllowed;
    if (listedKiddos && listedKiddos.length) {
      return isParentAllowed && listedKiddos.length > 0;
    }
    return isParentAllowed;
  }, [
    usertype,
    allowedUsers,
    listedKiddos,
    screenType,
    checkIfAllowedInCompany,
    checkIfAllowedToUser,
  ]);

  return allowed ? (
    /**
     * @todo buatkan sidebar item ini menjadi sebuah `Link` atau `a`, sehingga menjadi benar secara semantik
     * sehingga ketika item ini diklik kanan, kita bisa buka di new tab.
     */
    <div className={[classes.container].join(" ")}>
      {!kiddos || kiddos.length === 0 ? (
        <Link
          to={{
            pathname: parentURL,
          }}
          className={[
            classes.buttonStyle,
            open ? "open" : undefined,
            match ? "match" : undefined,
            !isExpanded ? "shrink" : undefined,
          ].join(" ")}
        >
          <span
            style={{
              marginRight: isExpanded ? "8.5px" : 0,
              flexBasis: "1rem",
            }}
          >
            {Icon ? <Icon /> : []}
          </span>
          {isExpanded && (
            <span
              className={[classes.title, !isExpanded ? "shrink" : ""].join(" ")}
            >
              <p style={{ margin: 0, width: "max-content" }}>{title}</p>
            </span>
          )}
        </Link>
      ) : (
        listedKiddos &&
        listedKiddos.length > 0 && (
          <button
            className={[
              classes.buttonExpandStyle,
              open && "open",
              !isExpanded && "shrink",
            ].join(" ")}
            onClick={(e) => {
              e.stopPropagation();
              setOpen((val) => !val);
            }}
          >
            <span
              style={{
                marginRight: isExpanded ? "8.5px" : 0,
                flexBasis: "1rem",
              }}
            >
              {Icon ? <Icon /> : []}
            </span>
            {isExpanded && (
              <span
                className={[classes.title, !isExpanded ? "shrink" : ""].join(
                  " ",
                )}
              >
                <p style={{ margin: 0, width: "max-content" }}>{title}</p>
              </span>
            )}
            {listedKiddos && listedKiddos.length ? (
              <span
                style={{
                  flexBasis: "1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BsChevronDown
                  className={[classes.chevron, open ? "open" : ""].join(" ")}
                />
              </span>
            ) : (
              []
            )}
          </button>
        )
      )}
      {listedKiddos && listedKiddos.length ? (
        <div
          className={[classes.kiddosContainer, open ? "open" : ""].join(" ")}
        >
          {listedKiddos.map(({ title, shrinkTitle, kiddoURL }, i) => {
            return (
              <Link
                to={{
                  pathname: kiddoURL,
                }}
                className={[
                  classes.aKiddo,
                  matchKiddo === kiddoURL ? "match" : "",
                ].join(" ")}
                key={`${i}-${title}`}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   let url = `${kiddoURL}`;
                //   if (!isGreaterThan768 && isExpanded) {
                //     dispatch(toggleSidebar(false));
                //   }
                //   navigate(url);
                // }}
              >
                <span
                  className={[classes.title, !isExpanded ? "shrink" : ""].join(
                    " ",
                  )}
                  style={{
                    marginLeft: isExpanded ? "2rem" : 0,
                  }}
                >
                  <p
                    style={{
                      margin: isExpanded ? 0 : "auto",
                      width: "max-content",
                    }}
                  >
                    {isExpanded ? title : shrinkTitle}
                  </p>
                </span>
              </Link>
            );
          })}
        </div>
      ) : (
        []
      )}
    </div>
  ) : (
    <></>
  );
};

export default SidebarItem;
