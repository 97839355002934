import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape, UsedAPI } from "../../api/config";
import CookieMonster from "../../api/CookieMonster";
import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { AnEmployeeType, EmployeeTypeObj } from "../../api/employeeAPI";
import { employeeStoreAPI } from "../employeeStore/employeeStoreAPI";

const employeetypeURL = "/employeetype";

export const employeeTypeStoreAPI = employeeStoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getEmployeeType: builder.query<
        AnEmployeeType[],
        Partial<EmployeeTypeObj>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          // let url = "/employeetype" + "/find" + params;
          let url = `${employeetypeURL}/find${params}`;
          return {
            url,
            method: "GET",
          };
        },
        transformResponse: (response: BackendDataShape<AnEmployeeType[]>) =>
          response.data,
        providesTags: ["EmployeeTypeList"],
      }),
      createEmployeeType: builder.mutation<
        BackendDataShape<AnEmployeeType>,
        EmployeeTypeObj
      >({
        query: (obj) => {
          return {
            url: employeetypeURL + "/create",
            body: obj,
            method: "POST",
          };
        },
        invalidatesTags: ["EmployeeTypeList"],
      }),
      updateEmployeeType: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
          data: Partial<EmployeeTypeObj>;
        }
      >({
        query: ({ data, id }) => {
          return {
            url: employeetypeURL + `/update/${id}`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["EmployeeTypeList"],
      }),

      deleteEmployeeType: builder.mutation<
        BackendDataShape<string>,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: employeetypeURL + `/delete/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["EmployeeTypeList"],
      }),
    };
  },
});

export const {
  useCreateEmployeeTypeMutation,
  useDeleteEmployeeTypeMutation,
  useGetEmployeeTypeQuery,
  useUpdateEmployeeTypeMutation,
  util: { resetApiState: resetEmployeeTypeStoreAPI },
} = employeeTypeStoreAPI;
