import React from "react";
import toast from "react-hot-toast";
import CustomToast from "../Toast/CustomToast";
import { KnownUsertype } from "../../api/credentialsAPI/usertype";
import useUsertype from "../../hooks/auth/useUsertype";
import NoMatch from "../../pages/NoMatch";
import useGetCompanyPermissions from "../../hooks/company/useGetCompanyPermissions";
import CookieMonster from "../../api/CookieMonster";
import LoadingScreen from "../atoms/LoadingScreen/LoadingScreen";
import {
  ADashboardPermission,
  AManagementPermission,
  AOtherPermission,
} from "../../api/permissionAPI";
import { useGetMyPermissionQuery } from "../../store/userStore/userStoreAPI";

interface Props {
  allowedUsers: KnownUsertype[];
  /**
   *
   */
  screenType?: ScreenType;
}

type ScreenType =
  | {
      type: "dashboard";
      screenName: keyof ADashboardPermission;
    }
  | {
      type: "management";
      screenName: keyof AManagementPermission;
    }
  | {
      type: "other";
      screenName: keyof AOtherPermission;
    };

// type ScreenType<D extends "dashboard" | "management" | "other" = "dashboard"> = D extends "dashboard" ? {
//   type: D,
//   screenName: keyof ADashboardPermission;
// } : D extends "management" ? {
//   type: D,
//   screenName: keyof AManagementPermission;
// }: D extends "other" ? {
//   type: D,
//   screenName: keyof AOtherPermission
// } : never

/**
 *  We assume that the jwt was checked before
 */
const UserGate: React.FunctionComponent<Props> = ({
  children,
  allowedUsers,
  screenType,
}) => {
  let usertype = useUsertype();
  const {
    isLoading: isLoadingCompanyPermission,
    permission: companyPermissions,
  } = useGetCompanyPermissions({
    args: {
      id: CookieMonster.loadCookie("companyId"),
    },
    options: {
      skip: !usertype || usertype === "systemadmin",
    },
  });

  const { isLoading: isLoadingMyPermission, data: userPermission } =
    useGetMyPermissionQuery(
      {},
      {
        skip:
          !usertype || usertype === "systemadmin" || usertype === "superadmin",
      },
    );

  if (usertype) {
    let usertypePermitted = !!allowedUsers.find((user) => usertype === user);
    let companyPermitted = true;
    let userPermitted = true;
    try {
      if (usertype === "systemadmin") {
        if (usertypePermitted) return <>{children}</>;
        throw new Error("Not Permitted");
      }

      if (isLoadingCompanyPermission) {
        return <LoadingScreen />;
      }
      switch (screenType?.type) {
        case "dashboard":
          companyPermitted = !!companyPermissions.dashboard.find((str) => {
            return str === screenType.screenName;
          });
          break;
        case "management":
          companyPermitted = !!companyPermissions.management.find((str) => {
            return str === screenType.screenName;
          });
          break;
        case "other":
          companyPermitted = !!companyPermissions.other.find((str) => {
            return str === screenType.screenName;
          });
          break;
        default:
          companyPermitted = true;
          break;
      }

      if (usertype === "superadmin") {
        if (usertypePermitted && companyPermitted) return <>{children}</>;
        throw new Error("Not Permitted");
      }

      if (isLoadingMyPermission) {
        return <LoadingScreen />;
      }

      if (!userPermission) throw new Error("Not Permitted");

      switch (screenType?.type) {
        case "dashboard":
          userPermitted = !!userPermission.permissions.dashboard.find((str) => {
            return str === screenType.screenName;
          });
          break;
        case "management":
          userPermitted = !!userPermission.permissions.management.find(
            (str) => {
              return str === screenType.screenName;
            },
          );
          break;
        case "other":
          userPermitted = !!userPermission.permissions.other.find((str) => {
            return str === screenType.screenName;
          });
          break;
        default:
          userPermitted = true;
          break;
      }

      if (usertypePermitted && companyPermitted && userPermitted)
        return <>{children}</>;
      throw new Error("not permitted");
    } catch (e) {
      const error = e as Error;
      // console.error(error);
      toast.custom(
        (t) => (
          <CustomToast
            t={t}
            headerText="Error!"
            description={"You dont have enough credential to access the page"}
            status="error"
          />
        ),
        { position: "top-right" },
      );
      return <NoMatch />;
    }
  } else {
    toast.custom(
      (t) => (
        <CustomToast
          t={t}
          headerText="Error!"
          description={"You dont have enough credential to access the page"}
          status="error"
        />
      ),
      { position: "top-right" },
    );
    return <NoMatch />;
  }
};

export default UserGate;
