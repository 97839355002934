export const ListOfRoutes = {
  loginpage: "/login",
  dashboard: "/dashboard",
  management: "/management",
  reports: "/reports",
  schedule: "/schedule",
  profile: "/profile",
  landingPage: "/landing",
  /**
   * @todo add links for another state such as
   * verify: "/verification/:verifyState"
   *
   * so then we can show page depending on the `verifyState`
   */
  verify: "/verification/success",

  /**
   * Dedicated machine OEE, used by operator
   */
  machineDetails: "/machine",
} as const;

export const DashboardRoutes = {
  /**
   * Energy meter dashboard
   */
  energyMeter: `${ListOfRoutes.dashboard}/energymeter`,
  /**
   * Energy meter dashboard devices. consist of detail data of each energy meter devices
   */
  energyMeterDetail: `${ListOfRoutes.dashboard}/emdevices`,
  energyMeterDetailWithId: `${ListOfRoutes.dashboard}/emdevices/:deviceId`,

  /**
   * OEE Dashboard.
   */
  oee: `${ListOfRoutes.dashboard}/oee`,
  /**
   * OEE Machine detail.
   */
  oeeMachines: `${ListOfRoutes.dashboard}/oee/machines`,
  /**
   * Water Level Dashboard
   */
  waterlevel: `${ListOfRoutes.dashboard}/waterlevel`,
  overallWaterLevel: `${ListOfRoutes.dashboard}/waterlevel`,
  /**
   * List Water Levels by Location
   */
  waterLevelByLocation: `${ListOfRoutes.dashboard}/waterlevel/location`,
  /**
   * List Water Levels Detail
   */
  waterLevelLocationById: (id: string | number) => {
    return `${ListOfRoutes.dashboard}/waterlevel/location/${id}` as const;
  },
  waterLevelBySublocation2Id: (id: string | number) => {
    return `${ListOfRoutes.dashboard}/waterlevel/sublocation2/${id}` as const;
  },
  /**
   * Envirobox Dashboard.
   */
  envirobox: `${ListOfRoutes.dashboard}/envirobox`,
  /**
   * Envirobox Dashboard Detail.
   */
  enviroboxDetailWithId: (deviceId: string | number) => {
    return `${ListOfRoutes.dashboard}/envirobox/${deviceId}` as const;
  },
  /**
   * Weather Station Dashboard
   */
  weatherStation: `${ListOfRoutes.dashboard}/weatherstation`,
  /**
   * Employee Tracker Dashboard
   */
  employeeTracker: `${ListOfRoutes.dashboard}/employeetracker`,
  /**
   * Employee Tracker Dashboard
   */
  employeeProfile: (employeeId: string | number) => {
    return `${ListOfRoutes.dashboard}/employeetracker/${employeeId}` as const;
  },
  /**
   * Employee Activities Dashboard
   */
  employeeActivities: `${ListOfRoutes.dashboard}/employee-activities`,
  /**
   * Employee Monitoring Dashboard
   */
  locationMonitoring: `${ListOfRoutes.dashboard}/location-monitoring`,
} as const;

export const EnergyMeterRoutes = {
  /**
   * Energy meter dashboard
   */
  energyMeter: `${ListOfRoutes.dashboard}/energymeter`,
  /**
   * Energy meter dashboard devices. consist of detail data of each energy meter devices
   */
  energyMeterDetail: `${ListOfRoutes.dashboard}/energymeter/devices`,
  energyMeterDetailWithId: `${ListOfRoutes.dashboard}/energymeter/devices/:deviceId`,
  energyMeterDeviceList: `${ListOfRoutes.dashboard}/energymeter/devices`,
} as const;

export const ManagementRoutes = {
  locations: `${ListOfRoutes.management}/locations`,
  company: `${ListOfRoutes.management}/company`,
  account: `${ListOfRoutes.management}/account`,
  gateway: `${ListOfRoutes.management}/gateway`,
  sensortypes: `${ListOfRoutes.management}/sensortypes`,
  devices: `${ListOfRoutes.management}/devices`,
  alerts: `${ListOfRoutes.management}/alerts`,
  workorder: `${ListOfRoutes.management}/workorder`,
  machine: `${ListOfRoutes.management}/machine`,
  product: `${ListOfRoutes.management}/product`,
  shift: `${ListOfRoutes.management}/shift`,
  area: `${ListOfRoutes.management}/area`,
  employee: `${ListOfRoutes.management}/employee`,
  employeetype: `${ListOfRoutes.management}/employeetype`,
  employeetag: `${ListOfRoutes.management}/employeetag`,
} as const;

export const ReportRoutes = {
  reports: `${ListOfRoutes.reports}`,
  schedule: `${ListOfRoutes.reports}/schedulereport`,
  step1: `${ListOfRoutes.reports}/step1`,
  step2: `${ListOfRoutes.reports}/step2`,
  step3: `${ListOfRoutes.reports}/step3`,
  step4: `${ListOfRoutes.reports}/step4`,
} as const;
